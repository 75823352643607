import { Injectable } from '@angular/core';
import { AwsAppSyncClientFactory } from '@app/factory/aws-app-sync-client.factory';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import AWSAppSyncClient from 'aws-appsync';

export enum FetchPolicy {
    CacheFirst = 'cache-first',
    CacheAndNetwork = 'cache-and-network',
    NetworkOnly = 'network-only',
    CacheOnly = 'cache-only',
    NoCache = 'no-cache',
}

@Injectable({
    providedIn: 'root',
})
export class AwsAppSyncClientProvider {
    private client: AWSAppSyncClient<NormalizedCacheObject>;

    constructor(private readonly awsAppSyncClientFactory: AwsAppSyncClientFactory) {
        Assert.notNullOrUndefined(awsAppSyncClientFactory, 'awsAppSyncClientFactory');
    }

    provide(): AWSAppSyncClient<NormalizedCacheObject> {
        return this.client || (this.client = this.createClient());
    }

    private createClient(): AWSAppSyncClient<NormalizedCacheObject> {
        return this.awsAppSyncClientFactory.create(environment.aws.appSync.url, environment.aws.region);
    }
}
