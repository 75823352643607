export enum ProduktArt {
    None = 0,
    Bewertung = 1,
    Ruecknahmebewertung = 2,
    Zustandsbericht = 3,
    NfzRuecknahmebewertung = 4,
    VtiTooling = 5,
    AlphaController = 6,
    CarGarantie = 7,
    Oldtimer = 9,
}

export enum ProduktIcon {
    Oldtimer = 'oldtimer',
}

export enum ProduktArtUebersicht {
    None = 0,
    Bewertung = 1,
    Ruecknahmebewertung = 2,
    Zustandsbericht = 3,
    VtiTooling = 5,
    CarGarantie = 7,
}

export enum AcProduktUbersichtFilter {
    None = 0,
    Alle = 'alle',
    Offen = 'offen',
    Bearbeitet = 'bearbeitet',
    Fertig = 'fertig',
}

export enum ProduktArtSelectBewertung {
    None = 0,
    Bewertung = 1,
    Ruecknahmebewertung = 2,
}

export enum ProduktArtSelectZustandsbericht {
    None = 0,
}

export enum ProduktArtNachbewertung {
    None = 0,
    Bewertung = 1,
    Ruecknahmebewertung = 2,
    Zustandsbericht = 3,
}

export enum ProduktStatus {
    None = 0,
    Offen = 1,
    Geschlossen = 2,
    Rechnung = 3,
    Versand = 4,
    Beendet = 5,
}

export enum ProduktRechnungsArt {
    None = 0,
    MitBVWS = 1,
    Manuell = 2,
    Ohne = 3,
}

export enum ProduktVersandArt {
    None = 0,
    MitRechnung = 1,
    OhneRechnung = 2,
    Kein = 3,
}

export enum AuftragArt {
    None = 0,
    Zentral = 1,
    Partner = 2,
}

export enum FahrzeugArt {
    None = 0,
    Pkw = 1,
    Lkw = 2,
    Transporter = 3,
    Kraftrad = 4,
    Omnibus = 5,
    Manuell = 100,
}

export enum FahrzeugBauform {
    None = 0,
    Kombi = 1,
    Limousine = 2,
    Coupe = 3,
    Multivan = 4,
    Cabriolet = 5,
    Kleinwagen = 6,
    SUV = 7,
    Manuell = 100,
    Sonstige = 1000,
}

export enum FahrzeugKraftradBauform {
    None = 0,
    Sonstige = 1000,
}

export enum FahrzeugAntriebsart {
    None = 0,
    Allradantrieb = 1,
    AllradantriebZuschaltbar = 2,
    Frontantrieb = 3,
    Heckantrieb = 4,
    Normalantrieb = 5,
    Sonstige = 1000,
}

export enum FahrzeugAntriebstechnologie {
    None = 0,
    Benzin = 1,
    Diesel = 2,
    Erdgas = 3,
    Fluessiggas = 4,
    Elektro = 5,
    Wasserstoff = 6,
    Hybrid = 7,
    Sonstige = 1000,
}

export enum FahrzeugExternalServicesReferenceSource {
    None = 0,
    DAT = 1,
    Audatex = 2,
    GTMotive = 3,
}

export enum FahrzeugGetriebart {
    None = 0,
    Manuell = 1,
    Automatik = 2,
}

export enum FahrzeugSchadstoffklasse {
    None = 0,
    Euro1 = 1,
    Euro2 = 2,
    Euro3 = 3,
    Euro4 = 4,
    Euro5 = 5,
    Euro6 = 6,
    Euro6B = 7,
    Euro6C = 8,
    Euro6DTemp = 9,
    Euro6DTempEvap = 10,
    Euro6D = 11,
}

export enum FahrzeugScheckheftGepflegt {
    None = 0,
    Hersteller = 1,
    Fachgerecht = 2,
    NichtGepflegt = 3,
    DigitalerWartungsnachweis = 4,
    Unbekannt = 5,
}

export enum FahrzeugLaufleistungEinheit {
    None = 0,
    Km = 1,
    Mi = 2,
}

export enum FahrzeugLaufleistungBeschreibung {
    abgelesen = 'abgelesen',
    angegeben = 'angegeben',
    geschätzt = 'geschätzt',
}

export enum FahrzeugAnzahlVorbesitzer {
    None = 0,
    KeinVorbesitzer = 20,
    EinVorbesitzer = 21,
    ZweiVorbesitzer = 22,
    DreiVorbesitzer = 23,
    VierVorbesitzer = 24,
    FuenfVorbesitzer = 25,
    SechsVorbesitzer = 26,
    SiebenVorbesitzer = 27,
    AchtVorbesitzer = 28,
    NeunVorbesitzer = 29,
    ZehnVorbesitzer = 30,
    ElfVorbesitzer = 31,
    ZwoelfVorbesitzer = 32,
    DreizehnVorbesitzer = 33,
    VierzehnVorbesitzer = 34,
    FuenfzehnVorbesitzer = 35,
    MehrAlsFuenfVorbesitzer = 91,
    MehrAlsFuenfzehnVorbesitzer = 92,
    UnbekannteAnzahlVorbesitzer = 99,
}

export enum FahrzeugAnzahlVorbesitzerLegacy {
    None = 0,
    EinVorbesitzer = 1,
    ZweiVorbesitzer = 2,
    DreiVorbesitzer = 3,
    VierVorbesitzer = 4,
    FuenfVorbesitzer = 5,
    SechsVorbesitzer = 6,
    SiebenVorbesitzer = 7,
    AchtVorbesitzer = 8,
    NeunVorbesitzer = 9,
    KeinVorbesitzer = 20,
    UnbekannteAnzahlVorbesitzer = 99,
}

export enum AdressArt {
    None = 0,
    Auftraggeber = 1,
    Fahrzeughalter = 2,
    Rechtsanwalt = 3,
    Werkstatt = 4,
    Besichtigungsort = 5,
    GtueSachverstaendigenbuero = 6,
    Versicherung = 7,
    Leasingnehmer = 8,
    Vermittler = 9,
}

export enum AdresseAnrede {
    None = 0,
    Herr = 1,
    Frau = 2,
    Firma = 3,
    Freitext = 4,
}

export enum RadBauart {
    None = 0,
    R = 1, // Radial
    D = 2, // Diagonal
}

export enum RadFelge {
    None = 0,
    Stahl = 1,
    Aluminium = 2,
}

export enum RadGeschwindigkeitsindex {
    None = 0,
    F = 1,
    G = 2,
    J = 3,
    K = 4,
    L = 5,
    M = 6,
    N = 7,
    P = 8,
    Q = 9,
    R = 10,
    S = 11,
    T = 12,
    U = 13,
    H = 14,
    V = 15,
    W = 16,
    Y = 17,
    VR = 18,
    ZR = 19,
}

export enum RadPosition {
    None = 0,
    VL = 1,
    VR = 2,
    HL = 3,
    HR = 4,
    LINKS = 5,
    RECHTS = 6,
    VA = 7,
    HA = 8,
}

export enum RadReifenTyp {
    None = 0,
    Ganzjahres = 1,
    Sommer = 2,
    Winter = 3,
}

export enum RadZulaessig {
    None = 0,
    InOrdnung = 1,
    Nachweisen = 2,
    NichtInOrdnung = 3,
}

export enum RadLagerort {
    None = 0,
    Montiert = 1,
    Demontiert = 2,
    ImFahrzeug = 3,
    Ausgelagert = 4,
    Eingelagert = 5,
    WirdNachgereicht = 6,
    Sonstige = 7,
}

export enum RaederNotlaufeinrichtung {
    None = 0,
    Notrad = 1,
    Ersatzrad = 2,
    Tirefit = 3,
    Runflat = 4,
    Faltrad = 5,
    NichtVorhanden = 6,
}

export enum VorschadenObergruppe {
    None = 0,
    Frontschaden = 1,
    SchwellerschadenLinks = 2,
    SeitenschadenLinks = 3,
    Heckschaden = 4,
    SeitenschadenRechts = 5,
    SchwellerschadenRechts = 6,
    SchadenAmUnterboden = 7,
    SchadenAmDach = 8,
}

export enum VorschadenUntergruppe {
    ASaeule = 'A-Säule',
    Abgasanlage = 'Abgasanlage',
    Antenne = 'Antenne',
    Achsen = 'Achsen',
    Antriebsaggregat = 'Antriebsaggregat',
    Antriebsstrang = 'Antriebsstrang',
    Aufnahmen = 'Aufnahmen',
    AussenspiegelLinks = 'Aussenspiegel Links',
    AussenspiegelRechts = 'Aussenspiegel rechts',
    BSaeule = 'B-Säule',
    CSaeule = 'C-Säule',
    Dach = 'Dach',
    Dachhimmel = 'Dachhimmel',
    Dachholm = 'Dachholm',
    DachholmLinks = 'Dachholm links',
    DachholmRechts = 'Dachholm rechts',
    DSaeule = 'D-Säule',
    Fronttraeger = 'Frontträger',
    Getriebe = 'Getriebe',
    Heckklappe = 'Heckklappe',
    HeckleuchteLinks = 'Heckleuchte links',
    HeckleuchteRechts = 'Heckleuchte rechts',
    Hecktraeger = 'Heckträger',
    Kofferraum = 'Kofferraum',
    KotfluegelVorneLinks = 'Kotflügel vorne links',
    KotfluegelVorneRechts = 'Kotflügel vorne rechts',
    Lastpfad = 'Lastpfad',
    Motorhaube = 'Motorhaube',
    Panoramadach = 'Panoramadach',
    RadVorneLinks = 'Rad vorne links',
    RadVorneRechts = 'Rad vorne rechts',
    RadHintenLinks = 'Rad hinten links',
    RadHintenRechts = 'Rad hinten rechts',
    Railing = 'Railing',
    ScheinwerferLinks = 'Scheinwerfer links',
    ScheinwerferRechts = 'Scheinwerfer rechts',
    Schiebedach = 'Schiebedach',
    Schwelleraufnahme = 'Schwelleraufnahme',
    SchwellerLinks = 'Schweller links',
    SchwellerRechts = 'Schweller rechts',
    Schwellerverkleidung = 'Schwellerverkleidung',
    SeitenwandHintenLinks = 'Seitenwand hinten links',
    SeitenwandHintenRechts = 'Seitenwand hinten rechts',
    Sensorik = 'Sensorik',
    StossfaengerVorne = 'Stoßfänger vorne',
    StossfaengerHinten = 'Stoßfänger hinten',
    TuerVorneLinks = 'Tür vorne links',
    TuerVorneRechts = 'Tür vorne rechts',
    TuerHintenLinks = 'Tür hinten links',
    TuerHintenRechts = 'Tür hinten rechts',
    TuerverkleidungVorneLinks = 'Türverkleidung vorne links',
    TuerverkleidungVorneRechts = 'Türverkleidung vorne rechts',
    TuerverkleidungHintenLinks = 'Türverkleidung hinten links',
    TuerverkleidungHintenRechts = 'Türverkleidung hinten rechts',
    Unterbau = 'Unterbau',
    Unterboden = 'Unterboden',
    Verstaerkungen = 'Verstärkung(en)',
    Windschutzscheibe = 'Windschutzscheibe',
}

export enum VorschadenArt {
    Nachlackierung = 'Nachlackierung',
    Unfall = 'Unfall',
    Sonstige = 'Sonstige',
    Abgelaufen = 'Abgelaufen',
    Abplatzung = 'Abplatzung',
    Abrieb = 'Abrieb',
    Abschuerfung = 'Abschürfung',
    BeschriftungBeschaedigtAbgetragen = 'Beschriftung beschädigt / abgetragen',
    Beschaedigt = 'Beschädigt',
    Beule = 'Beule',
    Brandloch = 'Brandloch',
    Delle = 'Delle',
    Dellen = 'Dellen',
    Defekt = 'Defekt',
    Deformiert = 'Deformiert',
    Fehler = 'Fehler',
    Fehlstellung = 'Fehlstellung',
    Fehlt = 'Fehlt',
    FunktionEingeschraenkt = 'Funktion eingeschränkt',
    Faellig = 'Fällig',
    Geruch = 'Geruch',
    Gebrochen = 'Gebrochen',
    Geknickt = 'Geknickt',
    Gerissen = 'Gerissen',
    Geraeusche = 'Geräusche',
    Softlackabloesungen = 'Softlackablösungen',
    Hagelschaden = 'Hagelschaden',
    Korrosion = 'Korrosion',
    Kratzer = 'Kratzer',
    Beklebung = 'Beklebung',
    Lackmangel = 'Lackmangel',
    LeuchtetAuf = 'Leuchtet auf',
    Lose = 'Lose',
    Loecher = 'Löcher',
    Marderschaden = 'Marderschaden',
    NichtSachUndFachgerecht = 'Nicht Sach- und fachgerecht',
    OhneFunktion = 'Ohne Funktion',
    Poroes = 'Porös',
    Rissig = 'Rissig',
    Schneelawine = 'Schneelawine',
    Spiel = 'Spiel',
    Sprung = 'Sprung',
    Steinschlag = 'Steinschlag',
    Undicht = 'Undicht',
    UnrunderLauf = 'Unrunder Lauf',
    Unvollstaendig = 'Unvollständig',
    Unzulaessig = 'Unzulässig',
    Verformt = 'Verformt',
    Verschuerft = 'Verschürft',
    Verkratzt = 'Verkratzt',
    Verschlissen = 'Verschlissen',
    Verschmutzt = 'Verschmutzt',
}

export enum VorschadenReparatur {
    SachUndFachgerechtRepariert = 'Sach- und fachgerecht repariert',
    Teilrepariert = 'Teilrepariert',
    UnsachgemaessRepariert = 'Unsachgemäß repariert',
    Aufbereitet = 'Aufbereitet',
    AusgelegtUndPoliert = 'Ausgelegt und poliert',
    BefundFestgelegtUndInstandgesetzt = 'Befund festgelegt und instandgesetzt',
    DemontiertMontier = 'Demontiert/ montiert',
    DiagnoseGestellt = 'Diagnose gestellt',
    Eingestellt = 'Eingestellt',
    Entfernt = 'Entfernt',
    Entklebt = 'Entklebt',
    Erneuert = 'Erneuert',
    GutachtenAngefordert = 'GutachtenAngefordert',
    InnenraumGereinigt = 'Innenraum gereinigt',
    Instandgesetzt = 'Instandgesetzt',
    InstandgesetztNachKalkuliertenDaten = 'Instandgesetzt nach kalkulierten Daten',
    InstandgesetztUndLackiert = 'Instandgesetzt und lackiert',
    Justiert = 'Justiert',
    Kalkulatiert = 'Kalkulatiert',
    Lackiert = 'Lackiert',
    Neutralisiert = 'Neutralisiert',
    OberflaechenLackiert = 'Oberflächen lackiert',
    NichtRepariert = 'Nicht repariert',
    Ozonbebehandelt = 'Ozonbebehandelt',
    Poliert = 'Poliert',
    Geprueft = 'Geprüft',
    Gereinigt = 'Gereinigt',
    Repariert = 'Repariert',
    SanftGedrueckt = 'Sanft gedrückt',
    SanftInstandgesetzt = 'SanftInstandgesetzt',
    SmartRepair = 'Smart-Repair',
    Getupft = 'Getupft',
    Vermessen = 'Vermessen',
    VermesseUndEingestellt = 'Vermesse Und Eingestellt',
    Sonstige = 'Sonstige',
}

export enum VorschadenReparaturMapNames {
    None = 'None',
    SachUndFachgerechtRepariert = 'Sach/Fachgerecht',
    Teilrepariert = 'Teilrepariert',
    UnsachgemaessRepariert = 'Unsachgemaess repariert',
    Sonstige = 'Sonstige',
}

export enum SchadenBeschaedigungart {
    Abgelaufen = 'Abgelaufen',
    Abplatzung = 'Abplatzung',
    Abrieb = 'Abrieb',
    Abschuerfung = 'Abschürfung',
    AbweichenderAuslieferungszustand = 'Abweichender Auslieferungszustand',
    BeschriftungBeschaedigtAbgetragen = 'Beschriftung beschädigt/ abgetragen',
    Beschaedigt = 'Beschädigt',
    Beule = 'Beule',
    Brandloch = 'Brandloch',
    Delle = 'Delle',
    Dellen = 'Dellen',
    Defekt = 'Defekt',
    Deformiert = 'Deformiert',
    ElFehler = 'El. Fehler',
    ElWarnleuchte = 'El. Warnleuchte',
    EntsprichtNichtDenLeasingvorgaben = 'Entspricht nicht den Leasingvorgaben',
    EntsprichtNichtDenBesichtigungsbedingungen = 'Entspricht nicht den Besichtigungsbedingungen',
    Fehler = 'Fehler',
    Fehlstellung = 'Fehlstellung',
    Fehlt = 'Fehlt',
    FunktionEingeschraenkt = 'Funktion eingeschränkt',
    Faellig = 'Fällig',
    Geruch = 'Geruch',
    Gebrauchsspur = 'Gebrauchsspur',
    Gebrochen = 'Gebrochen',
    Gerissen = 'Gerissen',
    Geraeusche = 'Geräusche',
    Softlackabloesungen = 'Softlackablösungen',
    Hagelschaden = 'Hagelschaden',
    Korrosion = 'Korrosion',
    Kratzer = 'Kratzer',
    Beklebung = 'Beklebung',
    Lackmangel = 'Lackmangel',
    LeuchtetAuf = 'Leuchtet auf',
    Lose = 'Lose',
    Loecher = 'Löcher',
    Marderschaden = 'Marderschaden',
    NichtSachUndFachgerecht = 'Nicht Sach- und fachgerecht',
    OhneFunktion = 'Ohne Funktion',
    Poroes = 'Porös',
    Rissig = 'Rissig',
    Spiel = 'Spiel',
    Sprung = 'Sprung',
    Steinschlag = 'Steinschlag',
    Undicht = 'Undicht',
    UnrunderLauf = 'Unrunder Lauf',
    Unvollstaendig = 'Unvollständig',
    Unzulaessig = 'Unzulässig',
    Verformt = 'Verformt',
    Verschuerft = 'Verschürft',
    Verkratzt = 'Verkratzt',
    Verschlissen = 'Verschlissen',
    Verschmutzt = 'Verschmutzt',
}

export enum SchadenIntensitaet {
    None = 0,
    Gebrauchsspur = 1,
    Leicht = 2,
    Mittel = 3,
    Schwer = 4,
    Grob = 5,
}

export enum SchadenGruppe {
    Exterieur = 1,
    Interieur = 2,
    Technik = 3,
}

export enum SchadenObergruppe {
    Abgasanlage = 'Abgasanlage',
    Antriebsstrang = 'Antriebsstrang',
    Armaturenbrett = 'Armaturenbrett',
    AussenspiegelLinks = 'Außenspiegel links',
    AussenspiegelRechts = 'Außenspiegel rechts',
    BremsanlageVorne = 'Bremsanlage vorne',
    BremsanlageHinten = 'Bremsanlage hinten',
    Dach = 'Dach',
    Dachhimmel = 'Dachhimmel',
    Differential = 'Differential',
    ElektrischesSystem = 'Elektrisches System',
    FussraumVorneLinks = 'Fußraum vorne links',
    FussraumVorneRechts = 'Fußraum vorne rechts',
    FussraumHintenLinks = 'Fußraum hinten links',
    FussraumHintenRechts = 'Fußraum hinten rechts',
    Getriebe = 'Getriebe',
    Glas = 'Glas',
    Handschuhfach = 'Handschuhfach',
    Heckklappe = 'Heckklappe',
    HeckleuchteLinks = 'Heckleuchte links',
    HeckleuchteRechts = 'Heckleuchte rechts',
    Hinterachse = 'Hinterachse',
    Innenraum = 'Innenraum',
    Innenspiegel = 'Innenspiegel',
    Interieur = 'Interieur',
    Karosserie = 'Karosserie',
    Kofferraum = 'Kofferraum',
    Komfortsysteme = 'Komfortsysteme',
    KotfluegelVorneLinks = 'Kotflügel vorne links',
    KotfluegelVorneRechts = 'Kotflügel vorne rechts',
    Lenkanlage = 'Lenkanlage',
    Mittelkonsole = 'Mittelkonsole',
    Motorhaube = 'Motorhaube',
    Motorraum = 'Motorraum',
    Pedalanlage = 'Pedalanlage',
    RadVorneLinks = 'Rad vorne links',
    RadVorneRechts = 'Rad vorne rechts',
    RadHintenLinks = 'Rad hinten links',
    RadHintenRechts = 'Rad hinten rechts',
    ScheinwerferLinks = 'Scheinwerfer links',
    ScheinwerferRechts = 'Scheinwerfer rechts',
    SchwellerLinks = 'Schweller links',
    SchwellerRechts = 'Schweller rechts',
    SeitenwandHintenLinks = 'Seitenwand hinten links',
    SeitenwandHintenRechts = 'Seitenwand hinten rechts',
    Sicherheitssysteme = 'Sicherheitssysteme',
    SitzVorneLinks = 'Sitz vorne links',
    SitzVorneRechts = 'Sitz vorne rechts',
    SitzHintenLinks = 'Sitz hinten links',
    SitzHintenMitte = 'Sitz hinten mitte',
    SitzHintenRechts = 'Sitz hinten rechts',
    StossfaengerVorne = 'Stoßfänger vorne',
    StossfaengerHinten = 'Stoßfänger hinten',
    TuerVorneLinks = 'Tür vorne links',
    TuerVorneRechts = 'Tür vorne rechts',
    TuerHintenLinks = 'Tür hinten links',
    TuerHintenRechts = 'Tür hinten rechts',
    TuerverkleidungVorneLinks = 'Türverkleidung vorne links',
    TuerverkleidungVorneRechts = 'Türverkleidung vorne rechts',
    TuerverkleidungHintenLinks = 'Türverkleidung hinten links',
    TuerverkleidungHintenRechts = 'Türverkleidung hinten rechts',
    Unterboden = 'Unterboden',
    Vorderachse = 'Vorderachse',
    Windschutzscheibe = 'Windschutzscheibe',
    Zubehoer = 'Zubehör',
}

export enum SchadenUntergruppe {
    /* Abgasanlage */
    Abgasanlage = 'Abgasanlage',
    AbgasanlageEndschalldaempfer = 'Endschalldämpfer',
    AbgasanlageAuspuffblenden = 'Auspuffblenden',
    /* Antriebsstrang */
    Antriebsstrang = 'Antriebsstrang',
    /* Armaturenbrett */
    Armaturenbrett = 'Armaturenbrett',
    ArmaturenbrettBezugArmaturenbrett = 'Bezug Armaturenbrett',
    ArmaturenbrettAblagefach = 'Ablagefach',
    ArmaturenbrettKombiinstrument = 'Kombiinstrument',
    ArmaturenbrettAbdeckungKombiinstrument = 'Abdeckung Kombiinstrument',
    ArmaturenbrettAbdeckungSicherungskastenLinks = 'Abdeckung Sicherungskasten links',
    ArmaturenbrettAbdeckungSicherungskastenRechts = 'Abdeckung Sicherungskasten rechts',
    ArmaturenbrettBedienelementHeizungKlima = 'Bedienelement Heizung Klima',
    SchalteinheitArmaturenbrettAllgemein = 'Schalteinheit Armaturenbrett allgemein',
    ArmaturenbrettLuftfuehrungLinks = 'Luftführung links',
    ArmaturenbrettLuftfuehrungRechts = 'Luftführung rechts',
    ArmaturenbrettLuftfuehrungMitte = 'Luftführung mitte',
    ArmaturenbrettBordcomputer = 'Bordcomputer',
    ArmaturenbrettGetraenkehalter = 'Getränkehalter',
    ArmaturenbrettDekor = 'Dekor',
    /* Außenspiegel links */
    AussenspiegelLinks = 'Außenspiegel links',
    AussenspiegelLinksSpiegelblinker = 'Spiegelblinker',
    AussenspiegelLinksSpiegelmotor = 'Spiegelmotor',
    AussenspiegelLinksSpiegelfuss = 'Spiegelfuß',
    AussenspiegelLinksSpiegelkappeLackiert = 'Spiegelkappe lackiert',
    AussenspiegelLinksSpiegelkappeUnterteil = 'Spiegelkappeunterteil',
    AussenspiegelLinksBefestigungssatzSpiegeleinheit = 'Befestigungssatz Spiegeleinheit',
    AussenspiegelLinksSpiegelglas = 'Spiegelglas',
    AussenspiegelLinksSpiegelglasBeheizt = 'Spiegelglas beheizt',
    AussenspiegelLinksSpiegelglasMitAnzeigeAssistentsystemen = 'Spiegelglas mit Anzeige Assistentsystemen',
    AussenspiegelLinksModulSpiegelstelleinheitInnenraum = 'Modul Spiegelstelleinheit (Innenraum)',
    /* Außenspiegel rechts */
    AussenspiegelRechts = 'Außenspiegel rechts',
    AussenspiegelRechtsSpiegelblinker = 'Spiegelblinker',
    AussenspiegelRechtsSpiegelmotor = 'Spiegelmotor',
    AussenspiegelRechtsSpiegelfuss = 'Spiegelfuß',
    AussenspiegelRechtsSpiegelkappeLackiert = 'Spiegelkappe lackiert',
    AussenspiegelRechtsSpiegelkappeUnterteil = 'Spiegelkappeunterteil',
    AussenspiegelRechtsBefestigungssatzSpiegeleinheit = 'Befestigungssatz Spiegeleinheit',
    AussenspiegelRechtsSpiegelglas = 'Außenspiegel rechts Spiegelglas',
    AussenspiegelRechtsSpiegelglasBeheizt = 'Spiegelglas beheizt',
    AussenspiegelRechtsSpiegelglasMitAnzeigeAssistentsystemen = 'Spiegelglas mit Anzeige Assistentsystemen',
    AussenspiegelRechtsModulSpiegelstelleinheitInnenraum = 'Modul Spiegelstelleinheit (Innenraum)',
    /* Bremsanlage vorne */
    BremsanlageVorne = 'Bremsanlage vorne',
    BremsanlageVorneBremsscheibe = 'Bremsscheibe',
    BremsanlageVorneBremsbelag = 'Bremsbelag',
    BremsanlageVorneBremsfluessigkeit = 'Bremsflüssigkeit',
    /* Bremsanlage hinten */
    BremsanlageHinten = 'Bremsanlage hinten',
    BremsanlageHintenBremsscheibe = 'Bremsscheibe',
    BremsanlageHintenBremsbelag = 'Bremsbelag',
    BremsanlageHintenBremsfluessigkeit = 'Bremsflüssigkeit',
    /* Dach */
    Dach = 'Dach',
    DachFolierung = 'Folierung',
    DachAntenne = 'Antenne',
    DachDachreling = 'Dachreling',
    DachSchiebedach = 'Schiebedach',
    DachPanoramadach = 'Panoramadach',
    DachVerdeck = 'Verdeck',
    DachVerdeckscheibe = 'Verdeckscheibe',
    /* Dachhimmel */
    Dachhimmel = 'Dachhimmel',
    DachhimmelHaltegriffHintenLinks = 'Haltegriff hinten links',
    DachhimmelHaltegriffHintenRechts = 'Haltegriff hinten rechts',
    DachhimmelHaltegriffVorneLinks = 'Haltegriff vorne links',
    DachhimmelHaltegriffVorneRechts = 'Haltegriff vorne rechts',
    DachhimmelSonnenblendeVorneRechts = 'Sonnenblende vorne rechts',
    DachhimmelSonnenblendeVorneLinks = 'Sonnenblende vorne links',
    DachhimmelAblagefachMittigVorne = 'Ablagefach mittig vorne',
    DachhimmelAblagefachMittigHinten = 'Ablagefach mittig hinten',
    DachhimmelSpiegeleinheitVorneLinks = 'Spiegeleinheit vorne links',
    DachhimmelSpiegeleinheitVorneRechts = 'Spiegeleinheit vorne rechts',
    DachhimmelSpiegeleinheitHintenLinks = 'Spiegeleinheit hinten links',
    DachhimmelSpiegeleinheitHintenRechts = 'Spiegeleinheit hinten rechts',
    DachhimmelBedienheitVorneMittig = 'Bedienheit vorne mittig',
    DachhimmelBedienheitHintenMittig = 'Bedienheit hinten mittig',
    /* Differential */
    Differential = 'Differential',
    /* Elektrisches System*/
    ElektrischesSystem = 'Elektrisches System',
    ElektrischesSystemAirbagsystem = 'Airbagsystem',
    ElektrischesSystemHeizungKlima = 'Heizung/ Klima',
    ElektrischesSystemMultimediasystem = 'Multimediasystem',
    ElektrischesSystemEinparkassistent = 'Einparkassistent',
    ElektrischesSystemSchliessanlage = 'Schließanlage',
    /* Fussraum vorne links */
    FussraumVorneLinks = 'Fußraum vorne links',
    FussraumVorneLinksFussmatte = 'Fußmatte',
    FussraumVorneLinksArretierungFussmatte = 'Arretierung Fußmatte',
    /* Fussraum vorne rechts */
    FussraumVorneRechts = 'Fußraum vorne rechts',
    FussraumVorneRechtsFussmatte = 'Fußmatte',
    FussraumVorneRechtsArretierungFussmatte = 'Arretierung Fußmatte',
    /* Fussraum hinten links */
    FussraumHintenLinks = 'Fußraum hinten links',
    FussraumHintenLinksFussmatte = 'Fußmatte',
    FussraumHintenLinksArretierungFussmatte = 'Arretierung Fußmatte',
    /* Fussraum hinten rechts */
    FussraumHintenRechts = 'Fußraum hinten rechts',
    FussraumHintenRechtsFussmatte = 'Fußmatte',
    FussraumHintenRechtsArretierungFussmatte = 'Arretierung Fußmatte',
    /* Getriebe */
    Getriebe = 'Getriebe',
    /* Handschuhfach */
    Handschuhfach = 'Handschuhfach',
    HandschuhfachOberhalb = 'Handschuhfach oberhalb',
    HandschuhfachUnterhalb = 'Handschuhfach unterhalb',
    HandschuhfachKuehlung = 'Kühlung Handschuhfach',
    HandschuhfachBetaetigung = 'Betätigung Handschuhfach',
    HandschuhfachScharniere = 'Scharniere',
    HandschuhfachHalterung = 'Halterung',
    HandschuhfachDeaktivierungSeitenairbag = 'Deaktivierung Seitenairbag',
    /* Heckklappe */
    Heckklappe = 'Heckklappe',
    HeckklappeDichtung = 'Dichtung',
    HeckklappeBeschriftungSchriftzug = 'Beschriftung/ Schriftzug',
    HeckklappeLichteinheitHintenLinks = 'Lichteinheit hinten links',
    HeckklappeLichteinheitHintenRechts = 'Lichteinheit hinten rechts',
    HeckklappeHeckscheibe = 'Heckscheibe',
    HeckklappeHeckscheibendichtung = 'Heckscheibendichtung',
    HeckklappeDritteBremsleuchte = 'Dritte Bremsleuchte',
    HeckklappeHeckklappenschloss = 'Schloss',
    HeckklappeSchalterDrueckerHeckklappe = 'Schalter/ Drücker Heckklappe',
    HeckklappeFanghaken = 'Fanghaken',
    HeckklappeSpritzdueseHeckwischeranlage = 'Spritzdüse Heckwischeranlage',
    HeckklappeHeckwischerarm = 'Heckwischerarm',
    HeckklappeHeckwischer = 'Heckwischer',
    HeckklappeHeckwischermotor = 'Heckwischermotor',
    HeckklappeInnenverkleidung = 'Innenverkleidung',
    HeckklappeVerkleidungUnterhalb = 'Verkleidung unterhalb',
    HeckklappeSpoileraufsatz = 'Spoileraufsatz',
    HeckklappeScharniere = 'Heckklappescharniere',
    HeckklappeDaempfung = 'Heckklappendämpfung',
    HeckklappeKennzeichen = 'Kennzeichen',
    HeckklappeKennzeichenhalter = 'Kennzeichenhalter',
    HeckklappeKennzeichenbeleuchtung = 'Kennzeichenbeleuchtung',
    HeckklappeHeckrolloMechanisch = 'Heckrollo mechanisch',
    HeckklappeHeckrolloElektrisch = 'Heckrollo elektrisch',
    HeckklappeBetaetigung = 'Betätigung Heckklappe',
    HeckklappeArretierungDaempfung = 'Arretierung Heckklappendämpfung',
    HeckklappeArretierung = 'Arretierung Heckablage',
    HeckklappeSensorik = 'Sensorik Heckklappe',
    /* Heckleuchte links */
    HeckleuchteLinks = 'Heckleuchte links',
    HeckleuchteLinksRueckstrahler = 'Rückstrahler',
    HeckleuchteLinksBlinker = 'Blinker',
    HeckleuchteLinksNebelleuchte = 'Nebelleuchte',
    /* Heckleuchte rechts */
    HeckleuchteRechts = 'Heckleuchte rechts',
    HeckleuchteRechstRueckstrahler = 'Rückstrahler',
    HeckleuchteRechtsBlinker = 'Blinker',
    HeckleuchteRechtsNebelleuchte = 'Nebelleuchte',
    /* Hinterachse */
    Hinterachse = 'Hinterachse',
    HinterachseStossdaempferRechts = 'Stoßdämpfer HA rechts',
    HinterachseStossdaempferLinks = 'Stoßdämpfer HA links',
    HinterachseStossfaenger = 'Stoßfänger HA',
    /* Innenraum */
    Innenraum = 'Innenraum',
    /* Innespiegel */
    Innenspiegel = 'Innenspiegel',
    InnenspiegelSpiegelglas = 'Spiegelglas',
    InnenspiegelSpiegelverstellung = 'Spiegelverstellung',
    InnenspiegelRegensensor = 'Regelsensor',
    InnenspiegelKamera = 'Kamera',
    InnenspiegelSpiegelfuss = 'Spiegelfuß',
    InnenspiegelDisplay = 'Display',
    InnenspiegelTaxometer = 'Taxometer',
    InnenspiegelAbblendeinheit = 'Abblendeinheit',
    /* Interieur */
    Interieur = 'Interieur',
    InterieurASaeulenverkleidung = 'A- Säulenverkleidung',
    InterieurBSaeulenverkleidung = 'B- Säulenverkleidung',
    InterieurCSaeulenverkleidung = 'C- Säulenverkleidung',
    InterieurDSaeulenverkleidung = 'D- Säulenverkleidung',
    InterieurTeppichVorneLinks = 'Teppich vorne links',
    InterieurTeppichVorneRechts = 'Teppich vorne rechts',
    InterieurTeppichHintenLinks = 'Teppich hinten links',
    InterieurTeppichHintenRechts = 'Teppich hinten rechts',
    InterieurTeppichbefestigungVorneLinks = 'Teppichbefestigung vorne links',
    InterieurTeppichbefestigungVorneRechts = 'Teppichbefestigung vorne rechts',
    InterieurTeppichbefestigungHintenLinks = 'Teppichbefestigung hinten links',
    InterieurTeppichbefestigungHintenRechts = 'Teppichbefestigung hinten rechts',
    InterieurAblagenetz = 'Ablagenetz',
    InterieurSonnenblendeRechts = 'Sonnenblende rechts',
    InterieurSonnenblendeLinks = 'Sonnenblende links',
    InterieurSonnenblendenspiegelAbdeckungLinks = 'Sonnenblendenspiegel/ -abdeckung links',
    InterieurSonnenblendenspiegelAbdeckungRechts = 'Sonnenblendenspiegel/ -abdeckung rechts',
    InterieurBeleuchtungKomfortSonnenblendeLinks = 'Beleuchtung Komfort Sonnenblende links',
    InterieurBeleuchtungKomfortSonnenblendeRechts = 'Beleuchtung Komfort Sonnenblende rechts',
    InterieurGetraenkehalter = 'Getränkehalter',
    InterieurHaltegriffVorneLinks = 'Haltegriff vorne links',
    InterieurHaltegriffVorneRechts = 'Haltegriff vorne rechts',
    InterieurHaltegriffHintenLinks = 'Haltegriff hinten links',
    InterieurHaltegriffHintenRechts = 'Haltegriff hinten rechts',
    InterieurBrillenfach = 'Brillenfach',
    /* Karosserie */
    Karosserie = 'Karosserie',
    /* Kofferraum */
    Kofferraum = 'Kofferraum',
    KofferraumBoden = 'Boden',
    KofferraumTeppich = 'Teppich',
    KofferraumLadekantenschutz = 'Ladekantenschutz',
    KofferraumAbdeckungLinks = 'Abdeckung links',
    KofferraumAbdeckungRechts = 'Abdeckung rechts',
    KofferraumNetz = 'Netz',
    KofferraumEinhaengeoesenLinks = 'Einhängeösen links',
    KofferraumEinhaengeoesenRechts = 'Einhängeösen rechts',
    KofferraumAbdeckungLaderaum = 'Abdeckung Laderaum',
    /* Komfortsysteme */
    Komfortsysteme = 'Komfortsysteme',
    /* Kotflügel vorne links */
    KotfluegelVorneLinks = 'Kotflügel vorne links',
    KotfluegelVorneLinksBlinker = 'Blinker',
    KotfluegelVorneLinksSchriftzug = 'Schriftzug',
    KotfluegelVorneLinksKotfluegelverbreiterung = 'Kotflügelverbreiterung',
    KotfluegelVorneLinksRadhausschale = 'Radhausschale',
    KotfluegelVorneLinksFolierung = 'Folierung',
    /* Kotflügel vorne rechts */
    KotfluegelVorneRechts = 'Kotflügel vorne rechts',
    KotfluegelVorneRechtsBlinker = 'Blinker',
    KotfluegelVorneRechtsSchriftzug = 'Schriftzug',
    KotfluegelVorneRechtsKotfluegelverbreiterung = 'Kotflügelverbreiterung',
    KotfluegelVorneRechtsRadhausschale = 'Radhausschale',
    KotfluegelVorneRechtsFolierung = 'Folierung',
    /* Lenkanlage */
    Lenkanlage = 'Lenkanlage',
    LenkanlageLenkrad = 'Lenkrad',
    LenkanlageAirbagAbdeckung = 'Airbag Abdeckung',
    LenkanlageLenkradverstelleinheit = 'Lenkradverstelleinheit',
    LenkanlageLenksaule = 'Lenksäule',
    LenkanlageLenkgetriebe = 'Lenkgetriebe',
    LenkanlageEmblem = 'Emblem',
    LenkanlageHebelanlage = 'Hebelanlage',
    LenkanlageBedienfelder = 'Bedienfelder',
    /* Mittelkonsole */
    Mittelkonsole = 'Mittelkonsole',
    MittelkonsoleAbdeckung = 'Abdeckung',
    MittelkonsoleSchalthebel = 'Schalthebel',
    MittelkonsoleSchaltsack = 'Schaltsack',
    MittelkonsoleDekorSchalthebel = 'Dekor Schalthebel',
    MittelkonsoleSchalthebelleder = 'Schalthebelleder',
    MittelkonsoleAschenbecher = 'Aschenbecher',
    MittelkonsoleSchalteinheitJoystick = 'Schalteinheit Joystick',
    MittelkonsoleZigarettenanzuender = 'Zigarettenanzünder',
    MittelkonsoleAblagefach = 'Ablagefach',
    MittelkonsoleSchalteinheitLueftungHinten = 'Lüftung hinten',
    MittelkonsoleLuftfuehrungHinten = 'Luftführung hinten',
    MittelkonsoleArmlehne = 'Armlehne',
    /* Motorhaube */
    Motorhaube = 'Motorhaube',
    MotorhaubeDaemmmatte = 'Dämmmatte',
    MotorhaubeSchliessteil = 'Schließteil',
    MotorhaubeFanghaken = 'Fanghaken',
    MotorhaubeScharnierLinks = 'Scharnier links',
    MotorhaubeScharnierRechts = 'Scharnier rechts',
    MotorhaubeScharniere = 'Scharniere',
    MotorhaubeSpritzduesen = 'Spritzdüsen',
    MotorhaubeSchlauchSpritzduesen = 'Schlauchspritzdüsen',
    MotorhaubeBefestigungssatz = 'Befestigungssatz',
    MotorhaubeEmblem = 'Emblem',
    MotorhaubeBeklebungFolierung = 'Beklebung Folierung',
    MotorhaubeLufteinlasskanal = 'Lufteinlasskanal',
    MotorhaubeZiergitter = 'Ziergitter',
    MotorhaubeKuehlergrill = 'Kühlergrill',
    /* Motorraum */
    Motorraum = 'Motorraum',
    MotorraumMotor = 'Motor',
    MotorraumMotorabdeckung = 'Motorabdeckung',
    MotorraumKuehlwasserverschlussdeckel = 'Kühlwasserverschlussdeckel',
    MotorraumKuehler = 'Kühler',
    MotorraumLuftfilter = 'Luftfilter',
    MotorraumScheibenklarverschlussdeckel = 'Scheibenklarverschlussdeckel',
    MotorraumBremsfluessigkeitdeckel = 'Bremsflüssigkeitdeckel',
    MotorraumOelmessstab = 'Ölmeßstab',
    MotorraumOelmessstutzen = 'Ölmeßstutzen',
    MotorraumMessstabGetriebeoel = 'Meßstab Getriebeöl',
    MotorraumFuellstandOel = 'Füllstand Öl',
    MotorraumFuellstandSpritzwasseranlage = 'Füllstand Spritzwasseranlage',
    MotorraumFuellstandGetriebe = 'Füllstand Getriebe',
    MotorraumFuellstandKuehlwasser = 'Füllstand Kühlwasser',
    MotorraumFuellstandBremsfluessigkeit = 'Füllstand Bremsflüssigkeit',
    /* Pedalanlage */
    Pedalanlage = 'Pedalanlage',
    PedalanlageGaspedal = 'Gaspedal',
    PedalanlageBremspedal = 'Bremspedal',
    PedalanlageKupplungspedal = 'Kupplungspedal',
    PedalanlageGummierungGaspedal = 'Gummierung Gaspedal',
    PedalanlageGummierungBremspedal = 'Gummierung Bremspedal',
    PedalanlageGummierungKupplungspedal = 'Gummierung Kupplungspedal',
    PedalanlageUmbauSonderfahrzeuge = 'Umbau Sonderfahrzeuge',
    /* Rad vorne links */
    RadVorneLinks = 'Rad vorne links',
    RadVorneLinksFelge = 'Felge',
    RadVorneLinksReifen = 'Reifen',
    RadVorneLinksNabendeckel = 'Nabendeckel',
    RadVorneLinksVentildeckel = 'Ventildeckel',
    RadVorneLinksRDKS = 'RDKS',
    RadVorneLinksFelgenschloss = 'Felgenschloss',
    /* Rad vorne rechts */
    RadVorneRechts = 'Rad vorne rechts',
    RadVorneRechtsFelge = 'Felge',
    RadVorneRechtsReifen = 'Reifen',
    RadVorneRechtsNabendeckel = 'Nabendeckel',
    RadVorneRechtsVentildeckel = 'Ventildeckel',
    RadVorneRechtsRDKS = 'RDKS',
    RadVorneRechtsFelgenschloss = 'Felgenschloss',
    /* Rad hinten links */
    RadHintenLinks = 'Rad hinten links',
    RadHintenLinksFelge = 'Felge',
    RadHintenLinksReifen = 'Reifen',
    RadHintenLinksNabendeckel = 'Nabendeckel',
    RadHintenLinksVentildeckel = 'Ventildeckel',
    RadHintenLinksRDKS = 'RDKS',
    RadHintenLinksFelgenschloss = 'Felgenschloss',
    /* Rad hinten rechts */
    RadHintenRechts = 'Rad hinten rechts',
    RadHintenRechtsFelge = 'Felge',
    RadHintenRechtsReifen = 'Reifen',
    RadHintenRechtsNabendeckel = 'Nabendeckel',
    RadHintenRechtsVentildeckel = 'Ventildeckel',
    RadHintenRechtsRDKS = 'RDKS',
    RadHintenRechtsFelgenschloss = 'Felgenschloss',
    /* Scheinwerfer links */
    ScheinwerferLinks = 'Scheinwerfer links',
    ScheinwerferLinksGlas = 'Scheinwerferglas links',
    ScheinwerferLinksLeuchtmittel = 'Leuchtmittel',
    /* Scheinwerfer rechts */
    ScheinwerferRechts = 'Scheinwerfer rechts',
    ScheinwerferRechtsGlas = 'Scheinwerferglas rechts',
    ScheinwerferRechtsLeuchtmittel = 'Leuchtmittel',
    /* Schweller links */
    SchwellerLinks = 'Schweller links',
    SchwellerLinksVerkleidung = 'Verkleidung',
    SchwellerLinksLufteinlassverkleidung = 'Luftweinlassverkleidung',
    SchwellerLinksDekor = 'Dekor',
    /* Schweller rechts */
    SchwellerRechts = 'Schweller rechts',
    SchwellerRechtsVerkleidung = 'Verkleidung',
    SchwellerRechtsLufteinlassverkleidung = 'Luftweinlassverkleidung',
    SchwellerRechtsDekor = 'Dekor',
    /* Seitenwand hinten links */
    SeitenwandHintenLinks = 'Seitenwand hinten links',
    SeitenwandHintenLinksFolierung = 'Folierung',
    SeitenwandHintenLinksSeitenscheibe = 'Seitenscheibe',
    SeitenwandHintenLinksRadhausverbreiterung = 'Radhausverbreiterung',
    SeitenwandHintenLinksRadhausschale = 'Radhausschale',
    SeitenwandHintenLinksTankdeckel = 'Tankdeckel',
    SeitenwandHintenLinksLichteinheit = 'Lichteinheit hinten',
    SeitenwandHintenLinksTankverschluss = 'Tankverschluss',
    SeitenwandHintenLinksTankverschlusssicherung = 'Tankverschlußsicherung',
    /* Seitenwand hinten rechts */
    SeitenwandHintenRechts = 'Seitenwand hinten rechts',
    SeitenwandHintenRechtsFolierung = 'Folierung',
    SeitenwandHintenRechtsSeitenscheibe = 'Seitenscheibe',
    SeitenwandHintenRechtsRadhausverbreiterung = 'Radhausverbreiterung',
    SeitenwandHintenRechtsRadhausschale = 'Radhausschale',
    SeitenwandHintenRechtsTankdeckel = 'Tankdeckel',
    SeitenwandHintenRechtsLichteinheit = 'Lichteinheit hinten',
    SeitenwandHintenRechtsTankverschluss = 'Tankverschluss',
    SeitenwandHintenRechtsTankverschlusssicherung = 'Tankverschlußsicherung',
    /* Sicherheitssysteme  */
    Sicherheitssysteme = 'Sicherheitssysteme',
    /* Sitze vorne links */
    SitzVorneLinks = 'Sitz vorne links',
    SitzVorneLinksSitzbezugUnterhalb = 'Sitzbezug unterhalb',
    SitzVorneLinksSitzbezugOberhalb = 'Sitzbezug oberhalb',
    SitzVorneLinksSicherheitsgurt = 'Sicherheitsgurt',
    SitzVorneLinksSitzheizung = 'Sitzheizung',
    SitzVorneLinksSitzlueftung = 'Sitzlüftung',
    SitzVorneLinksLordose = 'Lordose',
    SitzVorneLinksSitzverstellung = 'Sitzverstellung',
    SitzVorneLinksSchaltereinheitSitzverstellung = 'Schaltereinheit Sitzverstellung',
    SitzVorneLinksKopfstuetze = 'Kopfstütze',
    SitzVorneLinksAblagefach = 'Ablagefach',
    SitzVorneLinksSeitlicheAbdeckung = 'Seitliche Abdeckung',
    SitzVorneLinksAblagenetz = 'Ablagenetz',
    /* Sitze vorne rechts */
    SitzVorneRechts = 'Sitz vorne rechts',
    SitzVorneRechtsSitzbezugUnterhalb = 'Sitzbezug unterhalb',
    SitzVorneRechtsSitzbezugOberhalb = 'Sitzbezug oberhalb',
    SitzVorneRechtsSicherheitsgurt = 'Sicherheitsgurt',
    SitzVorneRechtsSitzheizung = 'Sitzheizung',
    SitzVorneRechtsSitzlueftung = 'Sitzlüftung',
    SitzVorneRechtsLordose = 'Lordose',
    SitzVorneRechtsSitzverstellung = 'Sitzverstellung',
    SitzVorneRechtsSchaltereinheitSitzverstellung = 'Schaltereinheit Sitzverstellung',
    SitzVorneRechtsKopfstuetze = 'Kopfstütze',
    SitzVorneRechtsAblagefach = 'Ablagefach',
    SitzVorneRechtsSeitlicheAbdeckung = 'Seitliche Abdeckung',
    SitzVorneRechtsAblagenetz = 'Ablagenetz',
    /* Sitz hinten links */
    SitzHintenLinks = 'Sitz hinten links',
    SitzHintenLinksSitzbezugUnterhalb = 'Sitzbezug unterhalb',
    SitzHintenLinksSitzbezugOberhalb = 'Sitzbezug oberhalb',
    SitzHintenLinksSitzheizung = 'Sitzheizung',
    SitzHintenLinksSitzlueftung = 'Sitzlüftung',
    SitzHintenLinksKopfstuetze = 'Kopfstütze',
    SitzHintenLinksSicherheitsgurt = 'Sicherheitsgurt',
    SitzHintenLinksGurtschnalle = 'Gurtschnalle',
    SitzHintenLinksGurtschloss = 'Gurtschloss',
    SitzHintenLinksGurtverstelleinheit = 'Gurtverstelleinheit',
    /* Sitz hinten mitte */
    SitzHintenMitte = 'Sitz hinten mitte',
    SitzHintenMitteSitzbezugUnterhalb = 'Sitzbezug unterhalb',
    SitzHintenMitteSitzbezugOberhalb = 'Sitzbezug oberhalb',
    SitzHintenMitteSitzheizung = 'Sitzheizung',
    SitzHintenMitteSitzlueftung = 'Sitzlüftung',
    SitzHintenMitteKopfstuetze = 'Kopfstütze',
    SitzHintenMitteSicherheitsgurt = 'Sicherheitsgurt',
    SitzHintenMitteGurtschnalle = 'Gurtschnalle',
    SitzHintenMitteGurtschloss = 'Gurtschloss',
    SitzHintenMitteGurtverstelleinheit = 'Gurtverstelleinheit',
    /* Sitze hinten rechts */
    SitzHintenRechts = 'Sitz hinten rechts',
    SitzHintenRechtsSitzbezugUnterhalb = 'Sitzbezug unterhalb',
    SitzHintenRechtsSitzbezugOberhalb = 'Sitzbezug oberhalb',
    SitzHintenRechtsSitzheizung = 'Sitzheizung',
    SitzHintenRechtsSitzlueftung = 'Sitzlüftung',
    SitzHintenRechtsKopfstuetze = 'Kopfstütze',
    SitzHintenRechtsSicherheitsgurt = 'Sicherheitsgurt',
    SitzHintenRechtsGurtschnalle = 'Gurtschnalle',
    SitzHintenRechtsGurtschloss = 'Gurtschloss',
    SitzHintenRechtsGurtverstelleinheit = 'Gurtverstelleinheit',
    /* Stoßfänger vorne */
    StossfaengerVorne = 'Stoßfänger vorne',
    StossfaengerVorneVerkleidung = 'Verkleidung',
    StossfaengerVorneKuehlergrill = 'Kühlergrill',
    StossfaengerVorneEmblem = 'Emblem',
    StossfaengerVorneLuftgitterLinks = 'Luftgitter links',
    StossfaengerVorneLuftgitterRechts = 'Luftgitter rechts',
    StossfaengerVorneFolierung = 'Folierung',
    StossfaengerVorneSpoilerlippe = 'Spoilerlippe',
    StossfaengerVorneKennzeichen = 'Kennzeichen',
    StossfaengerVorneKennzeichenhalter = 'Kennzeichenhalter',
    StossfaengerVorneNebelscheinwerferRechts = 'Nebelscheinwerfer rechts',
    StossfaengerVorneNebelscheinwerferLinks = 'Nebelscheinwerfer links',
    StossfaengerVorneEinparksensorUltraschallgeberLinks = 'Einparksensor (Ultraschallgeber) links',
    StossfaengerVorneEinparksensorUltraschallgeberLinksInnen = 'Einparksensor (Ultraschallgeber) links innen',
    StossfaengerVorneEinparksensorUltraschallgeberRechts = 'Einparksensor (Ultraschallgeber) rechts',
    StossfaengerVorneEinparksensorUltraschallgeberRechtsInnen = 'Einparksensor (Ultraschallgeber) rechts innen',
    StossfaengerVorneRadarsensorSpurhaltesystemLinks = 'Radarsensor Spurhaltesystem links',
    StossfaengerVorneRadarsensorSpurhaltesystemRechts = 'Radarsensor Spurhaltesystem rechts',
    StossfaengerVorneRadarsensorDistronicAbstandsmessung = 'Radarsensor Distronic Abstandsmessung',
    StossfaengerVorneScheinwerferreinigungsanlageLinks = 'Scheinwerferreinigungsanlage links',
    StossfaengerVorneScheinwerferreinigungsanlageRechts = 'Scheinwerferreinigungsanlage rechts',
    StossfaengerVorneKappeScheinwerferreinigungsanlageLinks = 'Scheinwerferreinigungsanlagekappe links',
    StossfaengerVorneKappeScheinwerferreinigungsanlageRechts = 'Scheinwerferreinigungsanlagekappe rechts',
    StossfaengerVorneAbschleppoesendeckel = 'Abschleppösenblende',
    StossfaengerVorneFrontkamera = 'Frontkamera',
    /* Stoßfänger hinten */
    StossfaengerHinten = 'Stoßfänger hinten',
    StossfaengerHintenStossfaengerverkleidung = 'Stoßfängerverkleidung',
    StossfaengerHintenKennzeichenhalter = 'Kennzeichenhalter',
    StossfaengerHintenKennzeichen = 'Kennzeichen',
    StossfaengerHintenSpoilerlippe = 'Spoilerlippe',
    StossfaengerHintenFolierung = 'Folierung',
    StossfaengerHintenAbschleppoesenblende = 'Abschleppösenblende',
    StossfaengerHintenKennzeichenbeleuchtungLinks = 'Kennzeichenbeleuchtung links',
    StossfaengerHintenKennzeichenbeleuchtungRechts = 'Kennzeichenbeleuchtung rechts',
    StossfaengerHintenLichteinheitLinks = 'Lichteinheit hinten links',
    StossfaengerHintenLichteinheitRechts = 'Lichteinheit hinten rechts',
    StossfaengerHintenNebelleuchte = 'Nebelleuchte',
    StossfaengerHintenRueckfahrkamera = 'Rückfahrkamera',
    StossfaengerHintenEinparksensorUltraschallgeberLinks = 'Einparksensor (Ultraschallgeber) links',
    StossfaengerHintenEinparksensorUltraschallgeberLinksInnen = 'Einparksensor (Ultraschallgeber) links innen',
    StossfaengerHintenEinparksensorUltraschallgeberRechts = 'Einparksensor (Ultraschallgeber) rechts',
    StossfaengerHintenEinparksensorUltraschallgeberRechtsInnen = 'Einparksensor (Ultraschallgeber) rechts innen',
    StossfaengerHintenStossRadarsensorSpurhaltesystemLinks = 'Radarsensor Spurhaltesystem links',
    StossfaengerHintenStossRadarsensorSpurhaltesystemRechts = 'Radarsensor Spurhaltesystem rechts',
    /* Tür vorne links */
    TuerVorneLinks = 'Tür vorne links',
    TuerVorneLinksTuerblatt = 'Türblatt',
    TuerVorneLinksTuerrahmen = 'Türrahmen',
    TuerVorneLinksZierleisteMittig = 'Zierleiste mittig',
    TuerVorneLinksScheibe = 'Scheibe',
    TuerVorneLinksScheibenfuehrungAussen = 'Scheibenführung außen',
    TuerVorneLinksScheibenfuehrungInnen = 'Scheibenführung innen',
    TuerVorneLinksRahmendichtungFensterfuehrung = 'Rahmendichtung Fensterführung',
    TuerVorneLinksElektrischeFensterheber = 'El. Fensterheber',
    TuerVorneLinksFensterheberkurbel = 'Fensterheberkurbel',
    TuerVorneLinksFolierung = 'Folierung',
    TuerVorneLinksScheibenheberMechanik = 'Scheibenheber/ mechanik',
    TuerVorneLinksFensterhebermotor = 'Fensterhebermotor',
    TuerVorneLinksModulFensterheberElektrisch = 'Modul Fensterheber elektrisch',
    TuerVorneLinksDaemmmatte = 'Dämmmatte',
    TuerVorneLinksSchlosstraeger = 'Schlossträger',
    TuerVorneLinksTuerschloss = 'Türschloss',
    TuerVorneLinksSchliesszylinder = 'Schließzylinder',
    TuerVorneLinksTuergriff = 'Türgriff',
    TuerVorneLinksBefestigungssatzTuer = 'Befestigungssatz Tür',
    TuerVorneLinksTuerdichtung = 'Türdichtung',
    TuerVorneLinksInnenverkleidung = 'Innenverkleidung',
    TuerVorneLinksInnengriff = 'Innengriff',
    TuerVorneLinksSchliessteilInnen = 'Schließteil innen',
    TuerVorneLinksRahmenSchliessteilInnen = 'Rahmen Schließteil innen',
    TuerVorneLinksTuerscharnier = 'Türscharnier',
    TuerVorneLinksTuerfangband = 'Türfangband',
    TuerVorneLinksZierleisteTuerInnen = 'Zierleiste Tür innen',
    TuerVorneLinksLautsprecherTuer = 'Lautsprecher Tür',
    TuerVorneLinksModulElektrischeVerrieglungEntriegelung = 'Modul el. Verrieglung/ Entriegelung',
    TuerVorneLinksVerkleidungTuerUnterhalb = 'Verkleidung Tür unterhalb',
    TuerVorneLinksBeschriftungFolierungTuer = 'Beschriftung/ Folierung Tür',
    /* Tür vorne rechts */
    TuerVorneRechts = 'Tür vorne rechts',
    TuerVorneRechtsTuerblatt = 'Türblatt',
    TuerVorneRechtsScheibe = 'Scheibe',
    TuerVorneRechtsTuerrahmen = 'Türrahmen',
    TuerVorneRechtsZierleisteMittig = 'Zierleiste mittig',
    TuerVorneRechtsScheibenfuehrungAussen = 'Scheibenführung außen',
    TuerVorneRechtsScheibenfuehrungInnen = 'Scheibenführung innen',
    TuerVorneRechtsRahmendichtungFensterfuehrung = 'Rahmendichtung Fensterführung',
    TuerVorneRechtsElektrischeFensterheber = 'El. Fensterheber',
    TuerVorneRechtsFensterheberkurbel = 'Fensterheberkurbel',
    TuerVorneRechtsFolierung = 'Folierung',
    TuerVorneRechtsScheibenheberMechanik = 'Scheibenheber/ mechanik',
    TuerVorneRechtsFensterhebermotor = 'Fensterhebermotor',
    TuerVorneRechtsModulFensterheberElektrisch = 'Modul Fensterheber elektrisch',
    TuerVorneRechtsDaemmmatte = 'Dämmmatte',
    TuerVorneRechtsSchlosstraeger = 'Schlossträger',
    TuerVorneRechtsTuerschloss = 'Türschloss',
    TuerVorneRechtsSchliesszylinder = 'Schließzylinder',
    TuerVorneRechtsTuergriff = 'Türgriff',
    TuerVorneRechtsBefestigungssatzTuer = 'Befestigungssatz Tür',
    TuerVorneRechtsTuerdichtung = 'Türdichtung',
    TuerVorneRechtsInnenverkleidung = 'Innenverkleidung',
    TuerVorneRechtsInnengriff = 'Innengriff',
    TuerVorneRechtsSchliessteilInnen = 'Schließteil innen',
    TuerVorneRechtsRahmenSchliessteilInnen = 'Rahmen Schließteil innen',
    TuerVorneRechtsTuerscharnier = 'Türscharnier',
    TuerVorneRechtsTuerfangband = 'Türfangband',
    TuerVorneRechtsZierleisteTuerInnen = 'Zierleiste Tür innen',
    TuerVorneRechtsLautsprecherTuer = 'Lautsprecher Tür',
    TuerVorneRechtsModulElektrischeVerrieglungEntriegelung = 'Modul el. Verrieglung/ Entriegelung',
    TuerVorneRechtsVerkleidungTuerUnterhalb = 'Verkleidung Tür unterhalb',
    TuerVorneRechtsBeschriftungFolierungTuer = 'Beschriftung/ Folierung Tür',
    /* Tür hinten links */
    TuerHintenLinks = 'Tür hinten links',
    TuerHintenLinksTuerblatt = 'Türblatt',
    TuerHintenLinksScheibe = 'Scheibe',
    TuerHintenLinksTuerrahmen = 'Türrahmen',
    TuerHintenLinksZierleisteMittig = 'Zierleiste mittig',
    TuerHintenLinksScheibenfuehrungAussen = 'Scheibenführung außen',
    TuerHintenLinksScheibenfuehrungInnen = 'Scheibenführung innen',
    TuerHintenLinksRahmendichtungFensterfuehrung = 'Rahmendichtung Fensterführung',
    TuerHintenLinksFensterheberkurbel = 'Fensterheberkurbel',
    TuerHintenLinksElektrischeFensterheber = 'Elektrische Fensterheber',
    TuerHintenLinksScheibenheberMechanik = 'Scheibenheber/ mechanik',
    TuerHintenLinksFensterhebermotor = 'Fensterhebermotor',
    TuerHintenLinksFolierung = 'Folierung',
    TuerHintenLinksModulFensterheberElektrisch = 'Modul Fensterheber elektrisch',
    TuerHintenLinksDaemmmatte = 'Dämmatte',
    TuerHintenLinksSchlosstraeger = 'Schlossträger',
    TuerHintenLinksTuerschloss = 'Türschloss',
    TuerHintenLinksSchliesszylinder = 'Schließzylinder',
    TuerHintenLinksTuergriff = 'Türgriff',
    TuerHintenLinksBefestigungssatzTuere = 'Befestigungssatz Türe',
    TuerHintenLinksTuerdichtung = 'Türdichtung',
    TuerHintenLinksInnenverkleidung = 'Innenverkleidung',
    TuerHintenLinksInnengriff = 'Innegriff',
    TuerHintenLinksSchliessteilInnen = 'Schließteil innen',
    TuerHintenLinksRahmenSchliessteilInnen = 'Rahmen Schließteil innen',
    TuerHintenLinksTuerscharnier = 'Türscharnier',
    TuerHintenLinksTuerfangband = 'Türfangband',
    TuerHintenLinksZierleisteTuerInnen = 'Zierleiste Tür innen',
    TuerHintenLinksLautsprecherTuer = 'Lautsprecher Tür',
    TuerHintenLinksModulElektrischeVerrieglungEntriegelung = 'Modul el. Verrieglung/ Entriegelung',
    TuerHintenLinksVerkleidungTuerUnterhalb = 'Verkleidung Tür unterhalb',
    TuerHintenLinksBeschriftungFolierungTuer = 'Beschriftung/ Folierung Tür',
    /* Tür hinten rechts */
    TuerHintenRechts = 'Tür hinten rechts',
    TuerHintenRechtsTuerblatt = 'Türblatt',
    TuerHintenRechtsScheibe = 'Scheibe',
    TuerHintenRechtsTuerrahmen = 'Türrahmen',
    TuerHintenRechtsZierleisteMittig = 'Zierleiste mittig',
    TuerHintenRechtsScheibenfuehrungAussen = 'Scheibenführung außen',
    TuerHintenRechtsScheibenfuehrungInnen = 'Scheibenführung innen',
    TuerHintenRechtsRahmendichtungFensterfuehrung = 'Rahmendichtung Fensterführung',
    TuerHintenRechtsFensterheberkurbel = 'Fensterheberkurbel',
    TuerHintenRechtsElektrischeFensterheber = 'Elektrische Fensterheber',
    TuerHintenRechtsScheibenheberMechanik = 'Scheibenheber/ mechanik',
    TuerHintenRechtsFensterhebermotor = 'Fensterhebermotor',
    TuerHintenRechtsFolierung = 'Folierung',
    TuerHintenRechtsModulFensterheberElektrisch = 'Modul Fensterheber elektrisch',
    TuerHintenRechtsDaemmmatte = 'Dämmatte',
    TuerHintenRechtsSchlosstraeger = 'Schlossträger',
    TuerHintenRechtsTuerschloss = 'Türschloss',
    TuerHintenRechtsSchliesszylinder = 'Schließzylinder',
    TuerHintenRechtsTuergriff = 'Türgriff',
    TuerHintenRechtsBefestigungssatzTuere = 'Befestigungssatz Türe',
    TuerHintenRechtsTuerdichtung = 'Türdichtung',
    TuerHintenRechtsInnenverkleidung = 'Innenverkleidung',
    TuerHintenRechtsInnengriff = 'Innegriff',
    TuerHintenRechtsSchliessteilInnen = 'Schließteil innen',
    TuerHintenRechtsRahmenSchliessteilInnen = 'Rahmen Schließteil innen',
    TuerHintenRechtsTuerscharnier = 'Türscharnier',
    TuerHintenRechtsTuerfangband = 'Türfangband',
    TuerHintenRechtsZierleisteTuerInnen = 'Zierleiste Tür innen',
    TuerHintenRechtsLautsprecherTuer = 'Lautsprecher Tür',
    TuerHintenRechtsModulElektrischeVerrieglungEntriegelung = 'Modul el. Verrieglung/ Entriegelung',
    TuerHintenRechtsVerkleidungTuerUnterhalb = 'Verkleidung Tür unterhalb',
    TuerHintenRechtsBeschriftungFolierungTuer = 'Beschriftung/ Folierung Tür',
    /* Türverkleidung vorne links */
    TuerverkleidungVorneLinks = 'Türverkleidung vorne links',
    TuerverkleidungVorneLinksOberhalb = 'Türverkleidung oberhalb',
    TuerverkleidungVorneLinksUnterhalb = 'Türverkleidung unterhalb',
    TuerverkleidungVorneLinksHaltegriff = 'Haltegriff vorne links',
    TuerverkleidungVorneLinksFensterheberschalter = 'Fensterheberschalter',
    TuerverkleidungVorneLinksSpiegelverstelleinheit = 'Spiegelverstelleinheit',
    TuerverkleidungVorneLinksZierleiste = 'Zierleiste',
    TuerverkleidungVorneLinksAblagefach = 'Ablagefach',
    TuerverkleidungVorneLinksSitzverstellungseinheit = 'Sitzverstellungseinheit',
    TuerverkleidungVorneLinksTuergriff = 'Türgriff',
    TuerverkleidungVorneLinksLautsprecher = 'Lautsprecher',
    TuerverkleidungVorneLinksLautsprecherabdeckung = 'Lautsprecherabdeckung',
    TuerverkleidungVorneLinksTuerverriegelungsschalter = 'Türverriegelungsschalter',
    TuerverkleidungVorneLinksTuerbeleuchtung = 'Türbeleuchtung',
    /* Türverkleidung vorne rechts */
    TuerverkleidungVorneRechts = 'Türverkleidung vorne rechts',
    TuerverkleidungVorneRechtsOberhalb = 'Türverkleidung oberhalb',
    TuerverkleidungVorneRechtsUnterhalb = 'Türverkleidung unterhalb',
    TuerverkleidungVorneRechtsHaltegriff = 'Haltegriff vorne rechts',
    TuerverkleidungVorneRechtsFensterheberschalter = 'Fensterheberschalter',
    TuerverkleidungVorneRechtsSpiegelverstelleinheit = 'Spiegelverstelleinheit',
    TuerverkleidungVorneRechtsZierleiste = 'Zierleiste',
    TuerverkleidungVorneRechtsAblagefach = 'Ablagefach',
    TuerverkleidungVorneRechtsSitzverstellungseinheit = 'Sitzverstellungseinheit',
    TuerverkleidungVorneRechtsTuergriff = 'Türgriff',
    TuerverkleidungVorneRechtsLautsprecher = 'Lautsprecher',
    TuerverkleidungVorneRechtsLautsprecherabdeckung = 'Lautsprecherabdeckung',
    TuerverkleidungVorneRechtsTuerverriegelungsschalter = 'Türverriegelungsschalter',
    TuerverkleidungVorneRechtsTuerbeleuchtung = 'Türbeleuchtung',
    /* Türverkleidung hinten links */
    TuerverkleidungHintenLinks = 'Türverkleidung hinten links',
    TuerverkleidungHintenLinksOberhalb = 'Türverkleidung oberhalb',
    TuerverkleidungHintenLinksUnterhalb = 'Türverkleidung unterhalb',
    TuerverkleidungHintenLinksHaltegriff = 'Haltegriff',
    TuerverkleidungHintenLinksFensterheberschalter = 'Fensterheberschalter',
    TuerverkleidungHintenLinksZierleiste = 'Zierleiste',
    TuerverkleidungHintenLinksAblagefach = 'Ablagefach',
    TuerverkleidungHintenLinksTuergriff = 'Türgriff',
    TuerverkleidungHintenLinksLautsprecher = 'Lautsprecher',
    TuerverkleidungHintenLinksLautsprecherabdeckung = 'Lautsprecherabdeckung',
    TuerverkleidungHintenLinksTuerbeleuchtung = 'Türbeleuchtung',
    /* Türverkleidung hinten rechts */
    TuerverkleidungHintenRechts = 'Türverkleidung hinten rechts',
    TuerverkleidungHintenRechtsOberhalb = 'Türverkleidung oberhalb',
    TuerverkleidungHintenRechtsUnterhalb = 'Türverkleidung unterhalb',
    TuerverkleidungHintenRechtsHaltegriff = 'Haltegriff',
    TuerverkleidungHintenRechtsFensterheberschalter = 'Fensterheberschalter',
    TuerverkleidungHintenRechtsZierleiste = 'Zierleiste',
    TuerverkleidungHintenRechtsAblagefach = 'Ablagefach',
    TuerverkleidungHintenRechtsTuergriff = 'Türgriff',
    TuerverkleidungHintenRechtsLautsprecher = 'Lautsprecher',
    TuerverkleidungHintenRechtsLautsprecherabdeckung = 'Lautsprecherabdeckung',
    TuerverkleidungHintenRechtsTuerbeleuchtung = 'Türbeleuchtung',
    /* Unterboden */
    Unterboden = 'Unterboden',
    Unterbodenschutz = 'Unterbodenschutz',
    UnterbodenverkleidungVorne = 'Verkleidung vorne',
    UnterbodenverkleidungMittig = 'Verkleidung mittig',
    UnterbodenverkleidungHinten = 'Verkleidung hinten',
    UnterbodenVerkleidungGetriebe = 'Verkleidung Getriebe',
    UnterbodenVerkleidungSchweller = 'Verkleidung Schweller',
    /* Vorderachse */
    Vorderachse = 'Vorderachse',
    VorderachseStossdaempferRechts = 'Stoßdämpfer VA rechts',
    VorderachseStossdaempferLinks = 'Stoßdämpfer VA links',
    VorderachseStossfaenger = 'Stoßfänger VA',
    /* Windschutzscheibe */
    Windschutzscheibe = 'Windschutzscheibe',
    WindschutzscheibeDichtung = 'Dichtung',
    WindschutzscheibeRegensensor = 'Regensensor',
    WindschutzscheibeKamera = 'Kamera',
    WindschutzscheibeWischeranlage = 'Wischeranlage',
    WindschutzscheibeScheibenwischerLinks = 'Scheibenwischer links',
    WindschutzscheibeEinzelwischerMittig = 'Scheibenwischer mittig',
    WindschutzscheibeScheibenwischerRechts = 'Scheibenwischer rechts',
    /* Zubehör */
    Zubehoer = 'Zubehör',
    ZubehoerWarndreieck = 'Warndreieck',
    ZubehoerWarnweste = 'Warnweste',
    ZubehoerVerbandstasche = 'Verbandstasche',
    ZubehoerReifendichtmittel = 'Reifendichtmittel',
    ZubehoerKompressor = 'Kompressor',
    ZubehoerWagenheber = 'Wagenheber',
    ZubehoerErsatzrad = 'Ersatzrad',
    ZubehoerBordwerkzeug = 'Bordwerkzeug',
    ZubehoerBordliteratur = 'Bordliteratur',
    ZubehoerFelgenschloss = 'Felgenschloss',
}

export enum SchadenReparaturweg {
    Aufbereiten = 'Aufbereiten',
    AuslegenUndPolieren = 'Auslegen und Polieren',
    BefundFestlegenEvtlInstandSetzen = 'Befund festlegen evtl. Instandsetzen',
    DemontierenMontieren = 'Demontieren/ montieren',
    DiagnoseStellen = 'Diagnose stellen',
    Einstellen = 'Einstellen',
    Entfernen = 'Entfernen',
    Entkleben = 'Entkleben',
    Erneuern = 'Erneuern',
    Gebrauchsspur = 'Gebrauchsspur',
    GutachtenErforderlich = 'Gutachten erforderlich',
    Innenraumreinigung = 'Innenraumreinigung',
    Instandsetzen = 'Instandsetzen',
    InstandsetzenNachKalkuliertenDaten = 'Instandsetzen nach kalkulierten Daten',
    InstandsetzenUndLackieren = 'Instandsetzen und lackieren',
    Justieren = 'Justieren',
    KalkulationNoetig = 'Kalkulation nötig',
    KeineAbzuege = 'Keine Abzüge',
    KostenvoranschlagErforderlich = 'Kostenvoranschlag erforderlich',
    Lackieren = 'Lackieren',
    Neutralisieren = 'Neutralisieren',
    Oberflaechenlack = 'Oberflächenlack',
    OhneReparatur = 'Ohne Reparatur',
    Ozonbehandlung = 'Ozonbehandlung',
    Politur = 'Politur',
    Pruefen = 'Prüfen',
    Reinigen = 'Reinigen',
    Repariert = 'Repariert',
    SanftDruecken = 'Sanft drücken',
    SanftInstandSetzen = 'Sanft Instandsetzen',
    SmartRepair = 'Smart-Repair',
    Tupfen = 'Tupfen',
    Vermessung = 'Vermessung',
    VermessungMitEinstellen = 'Vermessung mit einstellen',
    Wertminderung = 'Wertminderung',
}

export enum UmfangObergruppe {
    KarosserieLackierungUndAufbau = 'Karosserie, Lackierung und Aufbau',
    MotorMotorraumUndAntrieb = 'Motor, Motorraum und Antrieb',
    Bremsanlage = 'Bremsanlage',
    FahrwerkUndLenkung = 'Fahrwerk und Lenkung',
    RaederUndReifen = 'Räder und Reifen',
    BeleuchtungsUndWarnvorrichtungen = 'Beleuchtungs- und Warnvorrichtungen',
    InnenraumUndSicherheit = 'Innenraum und Sicherheit',
    Bodengruppe = 'Bodengruppe',
    Sonderfeststellungen = 'Sonderfeststellungen',
    Sonstiges = 'Sonstiges',
}

export enum AusstattungArt {
    None = 0,
    ExternalService = 1,
    Manuell = 2,
}

export enum FehlteileBezeichnung {
    None = 0,
    ABEUnterlagen = 1,
    Adapterkabel = 2,
    Anhaengerkupplung = 3,
    Antenne = 4,
    AUBescheinigung = 5,
    Bordunterlagen = 6,
    Bordwerkzeug = 7,
    CDRadio = 8,
    COCPapiere = 9,
    Codekarte = 10,
    Fahrzeugschluessel = 11,
    Felgenschloss = 12,
    FernbedienungStandheizung = 13,
    Feuerloescher = 14,
    Freisprecheinrichtung = 15,
    Fussmatten = 16,
    Gepaecknetz = 17,
    HUBescheinigung = 18,
    Hutablage = 19,
    Laderaumrollo = 20,
    NaviCD = 21,
    NaviSpeicherkarte = 22,
    NaviSystem = 23,
    Notrad = 24,
    Reserverad = 25,
    SchluesselAnhaengerkupplung = 26,
    Serviceheft = 27,
    Tirefit = 28,
    Verbandkasten = 29,
    Wagenheber = 30,
    Warndreieck = 31,
    Warnweste = 32,
    Windschott = 33,
    Zigarettenanzuender = 34,
    ZweiterRadsatz = 35,
}

export enum LackmessungPosition {
    None = 0,
    TuerVorneLinks = 1,
    TuerHintenLinks = 2,
    SeitenwandHintenLinks = 3,
    Heckklappe = 4,
    StossfaengerHinten = 5,
    SeitenwandHintenRechts = 6,
    TuerHintenRechts = 7,
    TuerVorneRechts = 8,
    KotfluegelVorneRechts = 9,
    Motorhaube = 10,
    StossfaengerVorne = 11,
    KotfluegelVorneLinks = 12,
    Dach = 13,
}

export enum BesteuerungArt {
    None = 0,
    Differenzbesteuert = 1,
    Regelbesteuert = 2,
}

export enum WartungEinheit {
    None = 0,
    Km = 1,
    Mi = 2,
}

export enum WartungEinheitZeit {
    None = 0,
    Tage = 1,
    Days = 2,
}

export enum WerteRoundValue {
    None = 0,
    Netto = 1,
    Brutto = 2,
}

export enum AbrechnungProvider {
    None = 0,
    Gtue = 1,
    Dat = 2,
}

export enum AbrechnungService {
    None = 0,
    Bewertung = 100,
    Leasingrücknahme = 101,
    Vin = 200,
    Werte = 201,
    Kalkulation = 202,
}

export enum AnhangId {
    Kalkulation = '1000000001',
}

export enum PdfProduktArtFileNameSuffix {
    Bewertung = '_bewertung',
    Ruecknahmebewertung = '_leasingruecknahme',
    Default = '_evaluate-mobility',
}

export enum CgVersicherungsart {
    None = 0,
    Bauteileversichert = 1,
    Fahrzeugversichert = 2,
}

export enum CgBaugruppeBauteileversichert {
    Motor = 'Motor',
    SchaltAutomatikgetriebe = 'Schalt-/Automatikgetriebe',
    AchsVerteilergetriebe = 'Achs-/Verteilergetriebe',
    Kraftuebertragung = 'Kraftübertragung',
    Lenkung = 'Lenkung',
    Bremsanlage = 'Bremsanlage',
    Kraftstoffanlage = 'Kraftstoffanlage',
    ElektrischeAnlage = 'Elektrische Anlage',
    Kuehlsystem = 'Kühlsystem',
    Abgasanlage = 'Abgasanlage',
    Sicherheitssysteme = 'Sicherheitssysteme',
    Klimaanlage = 'Klimaanlage',
    Komfortelektrik = 'Komfortelektrik',
    Fahrdynamiksystem = 'Fahrdynamiksystem',
    Elektroantrieb = 'Elektroantrieb',
    Hybridantrieb = 'Hybridantrieb',
    KarosserieInnenraum = 'Karosserie/Innenraum',
}

export enum CgBaugruppeFahrzeugversichert {
    Karosserie = 'Karosserie',
    ElektrikElektronik = 'Elektrik/Elektronik',
    Fahrwerk = 'Fahrwerk',
    Unterbau = 'Unterbau',
    Antriebsstrang = 'Antriebsstrang',
    Aufbauten = 'Aufbauten',
    Sonderfahrzeuge = 'Sonderfahrzeuge',
}

export enum CgBauteil {
    /* Motor */
    MotorDichtringgehaeuse = 'Dichtringgehäuse',
    MotorOelkreislaufteile = 'mit dem Ölkreislauf in Verbindung stehende Innenteile',
    MotorMotorOelkuehler = 'Motor-Ölkühler',
    MotorMotorblock = 'Motorblock',
    MotorNockenwellengehaeuse = 'Nockenwellengehäuse',
    MotorOeldruckschalter = 'Öldruckschalter',
    MotorOelfiltergehaeuse = 'Ölfiltergehäuse',
    MotorOelstandsensor = 'Ölstandsensor',
    MotorOelwanne = 'Ölwanne',
    MotorSchwungscheibeMitZahnkranz = 'Schwungscheibe/Antriebsscheibe mit Zahnkranz',
    MotorSpannrolleFuerSteuerriemen = 'Spannrolle für Steuerriemen',
    MotorSteuergehaeusedeckel = 'Steuergehäusedeckel',
    MotorSteuerriemen = 'Steuerrriemen',
    MotorUmlenkrolleFuerSteuerriemen = 'Umlenkrolle für Steuerriemen',
    MotorVentilschaftDichtung = 'Ventilschaft-Dichtung',
    MotorZylinderkopf = 'Zylinderkopf',
    MotorZylinderkopfdichtung = 'Zylinderkopfdichtung',
    /* Schalt-/Automatikgetriebe */
    SchaltAutomatikgetriebeAntriebsscheibe = 'Antriebsscheibe',
    SchaltAutomatikgetriebeDoppelkupplung = 'Doppelkupplung des Doppelkupplungsgetriebes',
    SchaltAutomatikgetriebeDrehmomentwandler = 'Drehmomentwandler',
    SchaltAutomatikgetriebeGetriebeOehlkuehler = 'Getriebe-Ölkühler',
    SchaltAutomatikgetriebeGetriebegehaeuse = 'Getriebegehäuse',
    SchaltAutomatikgetriebeInnenteileDesSchaltUndAutomatikgetriebes = 'Innenteile des Schalt- und Automatikgetriebes',
    SchaltAutomatikgetriebeKupplungsGeberzylinder = 'Kupplungs-Geberzylinder',
    SchaltAutomatikgetriebeKupplungsNehmerzylinder = 'Kupplungs-Nehmerzylinder',
    SchaltAutomatikgetriebeKupplungsaktuator = 'Kupplungsaktuator',
    SchaltAutomatikgetriebeNasseAnfahrkupplung = 'Nasse Anfahrkupplung',
    SchaltAutomatikgetriebeSchaltaktuator = 'Schaltaktuator',
    SchaltAutomatikgetriebeSteuergeraetDesAutomatikgetriebes = 'Steuergerät des Automatikgetriebes',
    SchaltAutomatikgetriebeSteuergeraetDesAutomatisiertenSchaltgetriebes = 'Steuergerät des automatisierten Schaltgetriebes',
    /* Achs-/Verteilergetriebe */
    AchsVerteilergetriebeFlansch = 'Flansch',
    AchsVerteilergetriebeGetriebegehaeuse = 'Getriebegehäuse',
    AchsVerteilergetriebeInnenteileAchsVerteilergetriebe = 'Innenteile des Achs- und Verteilergetriebes',
    /* Kraftübertragung */
    KraftuebertragungAntriebswelle = 'Antriebswelle',
    KraftuebertragungAntriebswellenGelenk = 'Antriebswellen-Gelenk',
    KraftuebertragungDrehzahlsensorASR = 'Drehzahlsensor (ASR)',
    KraftuebertragungDruckspeicherASR = 'Druckspeicher (ASR)',
    KraftuebertragungHaldexKupplung = 'Haldex-Kupplung',
    KraftuebertragungHaldexpumpe = 'Haldexpumpe',
    KraftuebertragungHydraulikeinheitASR = 'Hydraulikeinheit (ASR)',
    KraftuebertragungKardanwelle = 'Kardanwelle',
    KraftuebertragungLadepumpeASR = 'Ladepumpe (ASR)',
    KraftuebertragungMittellagerAntriebswelle = 'Mittellager Antriebswelle',
    KraftuebertragungMittellagerKardanwelle = 'Mittellager (Kardanwelle)',
    KraftuebertragungRadlager = 'Radlager',
    KraftuebertragungRadnabe = 'Radnabe',
    KraftuebertragungSteuergeraetASR = 'Steuergerät (ASR)',
    KraftuebertragungTraktionssteuergeraet = 'Traktionssteuergerät',
    KraftuebertragungViscokupplung = 'Viscokupplung',
    /* Lenkung */
    LenkungElektrischeLenkungsverriegelung = 'elektrische Lenkungsverriegelung',
    LenkungElektrischerLenkhilfemotor = 'elektrischer Lenkhilfemotor',
    LenkungElektronischeBauteileDerLenkung = 'elektronische Bauteile der Lenkung',
    LenkungHydraulikpumpeLenkung = 'Hydraulikpumpe (Lenkung)',
    LenkungLenkgetriebeMitAllenInnenteilen = 'Lenkgetriebe mit allen Innenteilen',
    /* Bremsanlage */
    BremsanlageABSDrehzahlsensor = 'ABS-Drehzahlsensor',
    BremsanlageABSHydraulikeinheit = 'ABS-Hydraulikeinheit',
    BremsanlageABSSteuergeraet = 'ABS-Steuergerät',
    BremsanlageBremskraftbegrenzer = 'Bremskraftbegrenzer',
    BremsanlageBremskraftregler = 'Bremskraftregler',
    BremsanlageBremskraftverstaerker = 'Bremskraftverstärker',
    BremsanlageBremslichtschalter = 'Bremslichtschalter',
    BremsanlageBremssattel = 'Bremssattel',
    BremsanlageBremssatteltraeger = 'Bremssattelträger',
    BremsanlageHauptbremszylinder = 'Hauptbremszylinder',
    BremsanlageHydropneumatikDruckregler = 'Hydropneumatik-Druckregler',
    BremsanlageHydropneumatikDruckspeicher = 'Hydropneumatik-Druckspeicher',
    BremsanlageHydropneumatikSteuergeraet = 'Hydropneumatik-Steuergerät',
    BremsanlageRadbremszylinderDerTrommelbremse = 'Radbremszylinder der Trommelbremse',
    BremsanlageSteuergeraetFuerHandbremse = 'Steuergerät für Handbremse',
    BremsanlageVakuumPumpe = 'Vakuum-Pumpe',
    /* Kraftstoffanlage */
    KraftstoffanlageEinspritzpumpe = 'Einspritzpumpe',
    KraftstoffanlageElektronischeBauteileDesMotormanagements = 'elektronische Bauteile des Motormanagements',
    KraftstoffanlageHochdruckpumpe = 'Hochdruckpumpe',
    KraftstoffanlageKompressor = 'Kompressor',
    KraftstoffanlageKraftstoffpumpe = 'Kraftstoffpumpe',
    KraftstoffanlageTurbolader = 'Turbolader',
    KraftstoffanlageVorfoerderpumpe = 'Vorförderpumpe',
    /* Elektrische Anlage */
    ElektrischeAnlageBordcomputer = 'Bordcomputer',
    ElektrischeAnlageElektrischeLeitungenderelektronischenEinspritzanlage = 'elektrische Leitungen der elektronischen Einspritzanlage',
    ElektrischeAnlageElektronischeBauteileDerZuendanlage = 'elektronische Bauteile der Zündanlage',
    ElektrischeAnlageGenerator = 'Generator',
    ElektrischeAnlageGeneratorFreilauf = 'Generator-Freilauf',
    ElektrischeAnlageGeneratorRegler = 'Generator-Regler',
    ElektrischeAnlageHeizUndFrischluftgeblaesemotor = 'Heiz- und Frischluftgebläsemotor',
    ElektrischeAnlageKombiinstrument = 'Kombiinstrument',
    ElektrischeAnlageLesespule = 'Lesespule',
    ElektrischeAnlageNavigationsgeraet = 'Navigationsgerät',
    ElektrischeAnlageSicherungskasten = 'Sicherungskasten',
    ElektrischeAnlageSignalhorn = 'Signalhorn',
    ElektrischeAnlageStarter = 'Starter',
    ElektrischeAnlageStartgenerator = 'Startgenerator',
    ElektrischeAnlageSteuergeraete = 'Steuergeräte',
    ElektrischeAnlageVorgluehrelaisSteuergeraet = 'Vorglührelais/-steuergerät',
    ElektrischeAnlageWischermotor = 'Wischermotor',
    ElektrischeAnlageXenonsteuergeraet = 'Xenonsteuergerät',
    ElektrischeAnlageZuendkabel = 'Zündkabel',
    /* Kühlsystem */
    KuehlsystemHeizungswaermetauscher = 'Heizungswärmetauscher',
    KuehlsystemKuehlgeblaesemotor = 'Kühlgebläsemotor',
    KuehlsystemLuefterSteuergeraet = 'Lüfter-Steuergerät',
    KuehlsystemLuefterkupplung = 'Lüfterkupplung',
    KuehlsystemThermoschalter = 'Thermoschalter',
    KuehlsystemThermostat = 'Thermostat',
    KuehlsystemThermostatgehaeuse = 'Thermostatgehäuse',
    KuehlsystemWasserkuehlerMotor = 'Wasserkühler (Motor)',
    KuehlsystemWasserpumpe = 'Wasserpumpe',
    KuehlsystemZusatzwasserpumpe = 'Zusatzwasserpumpe',
    /* Abgasanlage */
    AbgasanlageAGRKuehler = 'AGR-Kühler',
    AbgasanlageElektronischeBauteileDerAbgasreinigungsanlage = 'elektronische Bauteile der Abgasreinigungsanlage',
    /* Sicherheitssysteme */
    SicherheitssystemeAbstandsradar = 'Abstandsradar',
    SicherheitssystemeAirbagSteuergeraet = 'Airbag-Steuergerät',
    SicherheitssystemeCrashSensor = 'Crash-Sensor',
    SicherheitssystemeElektrischeSteckverbindungen = 'elektrische Steckverbindungen',
    SicherheitssystemeGurtstrafferSteuergeraet = 'Gurtstraffer-Steuergerät',
    SicherheitssystemeKabelsaetze = 'Kabelsätze',
    SicherheitssystemeLenkradkontaktteil = 'Lenkradkontaktteil',
    SicherheitssystemeRadarsensor = 'Radarsensor',
    SicherheitssystemeReifendruckkontrollsystemSensor = 'Reifendruckkontrollsystem-Sensor',
    SicherheitssystemeReifendruckkontrollsystemSteuergeraet = 'Reifendruckkontrollsystem-Steuergerät',
    SicherheitssystemeSitzbelegungssensor = 'Sitzbelegungssensor',
    SicherheitssystemeSteuergeraetAdaptiveGeschwindigkeitsregelung = 'Steuergerät adaptive Geschwindigkeitsregelung',
    SicherheitssystemeSteuergeraetKollisionswarnsystem = 'Steuergerät Kollisionswarnsystem',
    SicherheitssystemeSteuergeraetSpurhalteassistent = 'Steuergerät Spurhalteassistent',
    SicherheitssystemeSteuergeraetVerkehrszeichenerkennung = 'Steuergerät Verkehrszeichenerkennung',
    SicherheitssystemeTotwinkelsensor = 'Totwinkelsensor',
    SicherheitssystemeVibrationsmotorSpurhalteassistent = 'Vibrationsmotor Spurhalteassistent',
    /* Klimaanlage */
    KlimaanlageKlimaKompressor = 'Klima-Kompressor',
    KlimaanlageKlimaKondensator = 'Klima-Kondensator',
    KlimaanlageKlimaLuefter = 'Klima-Lüfter',
    KlimaanlageKlimaSteuergeraet = 'Klima-Steuergerät',
    KlimaanlageKlimaVerdampfer = 'Klima-Verdampfer',
    KlimaanlageKlimaautomatikBedienteil = 'Klimaautomatik-Bedienteil',
    KlimaanlageKompressorkupplung = 'Kompressorkupplung',
    /* Komfortelektrik */
    KomfortelektrikFensterheberMotor = 'Fensterheber-Motor',
    KomfortelektrikFensterheberSchalter = 'Fensterheber-Schalter',
    KomfortelektrikFensterheberSteuergeraet = 'Fensterheber-Steuergerät',
    KomfortelektrikFrontRueckfahrkamera = 'Front-/Rückfahrkamera',
    KomfortelektrikFrontscheibenheizungselemente = 'Frontscheibenheizungselemente (ausgenommen Bruchschäden)',
    KomfortelektrikHeckscheibenheizungselemente = 'Heckscheibenheizungselemente (ausgenommen Bruchschäden)',
    KomfortelektrikMultifunktionskamera = 'Multifunktionskamera',
    KomfortelektrikSchiebedachMotor = 'Schiebedach-Motor',
    KomfortelektrikSchiebedachSchalter = 'Schiebedach-Schalter',
    KomfortelektrikSchiebedachSteuergeraet = 'Schiebedach-Steuergerät',
    KomfortelektrikSeitenkamera = 'Seitenkamera',
    KomfortelektrikSitzheizungssteuergeraet = 'Sitzheizungssteuergerät',
    KomfortelektrikSpiegelsteuergeraet = 'Spiegelsteuergerät',
    KomfortelektrikSteuergeraetSitzverstellung = 'Steuergerät-Sitzverstellung',
    KomfortelektrikTuerHeckklappenschloss = 'Tür-/Heckklappenschloss',
    KomfortelektrikTuersteuergeraet = 'Türsteuergerät',
    KomfortelektrikVerdeckSteuergeraet = 'Verdeck-Steuergerät',
    KomfortelektrikZentralverriegelungsMotor = 'Zentralverriegelungs-Motor',
    KomfortelektrikZentralverriegelungsSchalter = 'Zentralverriegelungs-Schalter',
    KomfortelektrikZentralverriegelungsSteuergeraet = 'Zentralverriegelungs-Steuergerät',
    /* Fahrdynamiksystem */
    FahrdynamiksystemBremsdrucksensor = 'Bremsdrucksensor',
    FahrdynamiksystemESPSteuergeraet = 'ESP-Steuergerät',
    FahrdynamiksystemGiermomentsensor = 'Giermomentsensor',
    FahrdynamiksystemLenkwinkelsensor = 'Lenkwinkelsensor',
    FahrdynamiksystemQuerbeschleunigungssensor = 'Querbeschleunigungssensor',
    FahrdynamiksystemRaddrehzahlsensor = 'Raddrehzahlsensor',
    FahrdynamiksystemSteuergeraetTraktionskontrolle = 'Steuergerät Traktionskontrolle',
    /* Elektroantrieb */
    ElektroantriebElektrischeHeizquelleFuerFahrgastraumbeheizung = 'elektrische Heizquelle für Fahrgastraumbeheizung',
    ElektroantriebElektrischerBremskraftverstaerker = 'elektrischer Bremskraftverstärker',
    ElektroantriebElektrischerKlimakompressor = 'elektrischer Klimakompressor',
    ElektroantriebElektromotorenDesAntriebs = 'Elektromotoren des Antriebs',
    ElektroantriebFahrzeugintegriertesNetzladegeraet = 'fahrzeugintegriertes Netzladegerät (nicht jedoch das Ladekabel)',
    ElektroantriebHochvoltverkabelung = 'Hochvoltverkabelung',
    ElektroantriebKuehlungsluefterFuerDieAntriebsbatterie = 'Kühlungslüfter für die Antriebsbatterie',
    ElektroantriebLeistungselektronikDesAntriebs = 'Leistungselektronik des Antriebs',
    ElektroantriebSpannungswandlerFuerDasBordsystem = 'Spannungswandler für das Bordsystem',
    ElektroantriebSteuergeraetDerAntriebsbatterie = 'Steuergerät der Antriebsbatterie',
    ElektroantriebWechselrichterFuerDasBordsystem = 'Wechselrichter für das Bordsystem',
    /* Hybridantrieb */
    HybridantriebElektrischeWasserpumpeDesHybridantriebs = 'elektrische Wasserpumpe des Hybridantriebs',
    HybridantriebElektromotorenDesHybridantriebs = 'Elektromotoren des Hybridantriebs',
    HybridantriebElektronischesSteuergeraetDerHybridfahrzeugbatterie = 'elektronisches Steuergerät der Hybridfahrzeugbatterie',
    HybridantriebFahrzeugseitigesPlugInNetzladegeraet = 'fahrzeugseitiges Plug-In-Netzladegerät (nicht jedoch das Ladekabel)',
    HybridantriebGeneratorenDesHybridantriebs = 'Generatoren des Hybridantriebs',
    HybridantriebGetriebeFuerDenHybridantrieb = 'Getriebe für den Hybridantrieb',
    HybridantriebHochvoltACDCWandler = 'Hochvolt AC/DC-Wandler',
    HybridantriebHochvoltverkabelung = 'Hochvoltverkabelung',
    HybridantriebKuehlerFuerDieHybridbatterie = 'Kühler für die Hybridbatterie',
    HybridantriebLeistungselektronikDesHybridantriebs = 'Leistungselektronik des Hybridantriebs',
    HybridantriebLuefterFuerDieHybridbatterie = 'Lüfter für die Hybridbatterie',
    HybridantriebSpannungswandler = 'Spannungswandler',
    HybridantriebSteuergeraetFuerdenHybridantrieb = 'Steuergerät für den Hybridantrieb',
    HybridantriebWechselrichterFuerDasHybridsystem = 'Wechselrichter für das Hybridsystem',
    HybridantriebZwoelfVACDCWandler = '12V AC/DC-Wandler',
    /* Karosserie/Innenraum */
    KarosserieInnenraumVerdeckHydraulikeinheit = 'Verdeck-Hydraulikeinheit',
    KarosserieInnenraumVerdeckSteuergeraet = 'Verdeck-Steuergerät',
    KarosserieInnenraumVerdeckpumpe = 'Verdeckpumpe',
}
