<h1 class="title" mat-dialog-title>
    {{ 'privacy.title' | translate }}
</h1>
<div class="content-wrapper" mat-dialog-content>
    {{ 'privacy.content' | translate }}
    <mat-list>
        <mat-list-item>
            <a href="/assets/files/20220328_Evaluate_Mobility_Lizenzbedingungen_allgemein_online.pdf" target="_blank">
                {{ 'privacy.lizenzbedingungen' | translate }}
            </a>
        </mat-list-item>
        <mat-list-item>
            <a href="/assets/files/202412_Evaluate_Mobility_Lizenzvertrag_v1_3.pdf" target="_blank">
                {{ 'privacy.lizenzvertrag' | translate }}
            </a>
        </mat-list-item>
        <mat-list-item>
            <a href="/assets/files/20220330_TOMs_Evaluate_Mobility.pdf" target="_blank">
                {{ 'privacy.toms' | translate }}
            </a>
        </mat-list-item>
        <!-- files -->
        <mat-divider />
        <div mat-subheader>{{ 'privacy.files' | translate }}</div>
        <mat-list-item>
            <a href="/assets/files/20220330_Auftragsverarbeiter_Subunternehmer_EM.pdf" target="_blank">
                {{ 'privacy.auftragsverarbeiter' | translate }}
            </a>
        </mat-list-item>
        <mat-list-item>
            <a href="/assets/files/20200324_Datenschutzinformationen.zip" target="_blank">
                {{ 'privacy.datenschutzinformationen' | translate }}
            </a>
        </mat-list-item>
    </mat-list>
</div>
<div mat-dialog-actions align="end">
    <button [mat-dialog-close] mat-button>
        {{ 'privacy.accept' | translate }}
    </button>
</div>
