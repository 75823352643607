import { Injectable } from '@angular/core';
import * as localforage from 'localforage';
import { from, Observable } from 'rxjs';

const PRIVACY_ACCEPTED_KEY = 'gtue-evaluate-mobility-privacy-accepted-20250117';

@Injectable({
    providedIn: 'root',
})
export class PrivacyService {
    isAccepted(): Observable<boolean> {
        const getItem = localforage.getItem<boolean>(PRIVACY_ACCEPTED_KEY);
        return from(getItem);
    }

    setIsAccepted(value: boolean): Observable<boolean> {
        const setItem = localforage.setItem<boolean>(PRIVACY_ACCEPTED_KEY, value);
        return from(setItem);
    }
}
