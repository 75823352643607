import { Injectable } from '@angular/core';
import { QueryOptions } from '@apollo/client';
import { AwsAppSyncClientProvider, FetchPolicy } from '@app/provider/aws-app-sync-client.provider';
import { OldtimerProdukt, OldtimerProduktInput } from '@data/domain/schema/types';
import { createOldtimerProduktMutation } from '@modules/oldtimer/graphql/mutations/create-oldtimer-produkt.mutation';
import { updateOldtimerProduktQuery } from '@modules/oldtimer/graphql/mutations/update-oldtimer-produkt.mutation';
import { getOldtimerProduktQuery } from '@modules/oldtimer/graphql/queries/oldtimer-produkt.query';
import { Assert } from '@shared/helper/assert';
import { DataProxy } from 'apollo-cache';
import { NEVER, Observable, from, of, tap } from 'rxjs';
import { catchError, map, mergeMap, timeout } from 'rxjs/operators';

const GET_NETWORK_TIMEOUT = 1000 * 5;

@Injectable({ providedIn: 'root' })
export class OldtimerGraphqlService {
    constructor(private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider) {
        Assert.notNullOrUndefined(awsAppSyncClientProvider, 'awsAppSyncClientProvider');
    }

    getOldtimerProdukt(id: string): Observable<OldtimerProdukt | undefined> {
        return this.query<OldtimerProdukt | undefined>(
            { query: getOldtimerProduktQuery, variables: { id } },
            (response) => response.oldtimerProdukt,
            GET_NETWORK_TIMEOUT,
        );
    }

    createOldtimerProdukt(input: OldtimerProduktInput): Observable<OldtimerProdukt | undefined> {
        const client = this.awsAppSyncClientProvider.provide();
        const mutatePromise = client.mutate<{
            createOldtimerProdukt: OldtimerProdukt;
        }>({
            mutation: createOldtimerProduktMutation,
            variables: {
                input,
            },
            fetchPolicy: 'no-cache',
        });
        return from(mutatePromise).pipe(
            map((response) => {
                return response.data?.['createOldtimerProdukt'];
            }),
        );
    }

    updateOldtimerProdukt(input: OldtimerProduktInput, id?: string): Observable<OldtimerProdukt | undefined> {
        if (!id) {
            return NEVER;
        }
        const client = this.awsAppSyncClientProvider.provide();
        const mutatePromise = client.mutate<{
            updateOldtimerProdukt: OldtimerProdukt;
        }>({
            mutation: updateOldtimerProduktQuery,
            variables: {
                id,
                input,
            },
            fetchPolicy: 'no-cache',
            update: (store: DataProxy) => {
                this.updateGetCache(store, id, (_oldtimerProdukt) => input as OldtimerProdukt);
            },
        });
        return from(mutatePromise).pipe(
            map((response) => {
                return response.data?.['updateOldtimerProdukt'];
            }),
        );
    }

    private query<TResult>(
        options: QueryOptions<{ id: string }>,
        get: (response: OldtimerProduktQuery) => TResult,
        due: number,
    ): Observable<TResult> {
        const client = this.awsAppSyncClientProvider.provide();
        client.hydrated();

        const cache$ = from(client.query<OldtimerProduktQuery>({ ...options, fetchPolicy: FetchPolicy.CacheOnly }));
        const network$ = from(
            client.query<OldtimerProduktQuery>({ ...options, fetchPolicy: FetchPolicy.CacheFirst }),
        ).pipe(
            tap((response) => {
                const id = options.variables?.id;
                if (!id) return;
                this.updateGetCache(client.cache, id, (_oldtimerProdukt) => response.data.oldtimerProdukt);
            }),
        );

        return cache$.pipe(
            mergeMap((cache) => {
                if (cache && cache.data && get(cache.data)) {
                    return network$.pipe(
                        timeout(due),
                        catchError(() => {
                            return of(cache);
                        }),
                    );
                } else {
                    return network$;
                }
            }),
            map((response) => get(response.data)),
        );
    }

    private updateGetCache(
        store: DataProxy,
        id: string,
        update: (oldtimerProdukt: OldtimerProdukt) => OldtimerProdukt,
    ): void {
        let data: { oldtimerProdukt: OldtimerProdukt | null } = { oldtimerProdukt: null };
        const variables = { id };
        try {
            data = store.readQuery<OldtimerProduktQuery>({
                query: getOldtimerProduktQuery,
                variables: { ...variables },
            }) || { oldtimerProdukt: null };
        } catch (error) {
            console.warn("Could not readQuery 'getOldtimerProdukt' from store: ", error);
        }

        data.oldtimerProdukt = update(data.oldtimerProdukt || ({} as OldtimerProdukt));
        store.writeQuery({ query: getOldtimerProduktQuery, data, variables: { ...variables } });
    }
}

interface OldtimerProduktQuery {
    oldtimerProdukt: OldtimerProdukt;
}
