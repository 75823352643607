export const environment = {
    name: 'DEV',
    production: false,
    appUrl: 'https://dev.bewertung-stage.gtue.de',
    aws: {
        region: 'eu-central-1',
        appSync: {
            url: 'https://zh63lqnwfffsphy7urh5pnzhjm.appsync-api.eu-central-1.amazonaws.com/graphql',
        },
        cognito: {
            url: 'https://auth.dev.bewertung-stage.gtue.de',
            endsession: 'https://auth-dev.staging.gtue.world/connect/endsession',
            clientId: '6os260v1v1f7sqfh4uaa6ct1p7',
            userPoolId: 'bZr5pvocn',
            identityPoolId: 'e7553b61-ece3-48e9-ae4c-c3da737f3ee5',
        },
        apiGateway: {
            url: 'https://api.dev.bewertung-stage.gtue.de',
        },
        s3: {
            upload: {
                name: 'gtue-evaluate-mobility-produkt-upload-dev',
            },
            entwurf: {
                name: 'gtue-evaluate-mobility-entwurf-pdf-dev',
            },
            einstellungen: {
                name: 'gtue-evaluate-mobility-einstellungen-bucket-dev',
            },
        },
    },
    bvws: {
        combiPlusOnline: {
            url: 'https://online.stag.combi-plus.de/Account/GtueSignIn',
        },
    },
};
