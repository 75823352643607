import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from '@app/service/auth.service';
import { KalkulationDownloadService } from '@data/api-gateway/service/kalkulation-download.service';
import { DatFirstPage, DatKalkulationService } from '@data/api-gateway/service/dat-kalkulation.service';
import {
  SchadenIntensitaet,
  SchadenObergruppe,
  SchadenUntergruppe,
  UmfangObergruppe
} from '@data/domain/schema/enum';
import {
  Feststellungen,
  FeststellungenInput, FeststellungenPosition,
  Textbaustein
} from '@data/domain/schema/type';
import { ProduktFeststellungenService } from '@data/domain/service/feature';
import { UpdateWerteService } from '@data/domain/service/feature/update-werte-service';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { OBERGRUPPE_TO_UNTERGRUPPE_MAP } from '@modules/produkt/config/produkt-schaden-gruppen.config';
import { ProduktDetailFeststellungenFormViewFactory } from '@modules/produkt/factory/feststellungen/produkt-detail-feststellungen-form-view.factory';
import { ProduktDetailFeststellungenPositionFormViewFactory } from '@modules/produkt/factory/feststellungen/produkt-detail-feststellungen-position-form-view.factory';
import { ProduktDetailKalkulationFormViewFactory } from '@modules/produkt/factory/kalkulation/produkt-detail-kalkulation-form-view.factory';
import { SchadenGruppenDisplay } from '@modules/produkt/page/produkt-detail-schaden/produkt-detail-schaden.component';
import { DatCalculateProService } from '@modules/produkt/service/dat-calculate-pro-service';
import { ModelFileService } from '@modules/produkt/service/model-file.service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { ModelLoadResult } from '@shared/component/three/gltf/gltf.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { ArrayValues, EnumValues, ObjectValues, Values } from '@shared/helper/values';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, from, Observable, of, Subscription } from 'rxjs';
import { first, mergeMap, take } from 'rxjs/operators';
import { EinstellungenService } from '@data/api-gateway/service/einstellungen.service';
import { UntypedFormGroup } from '@angular/forms';
import { ProduktDetailSchadenComponentBase } from '../base/produkt-detail-schaden-base.component';

interface ProduktDetailFeststellungenUntergruppeDialogData {
  form: ViewFormGroup;
  values: Values;
}

declare let SphinxClass: any;

@Component({
  selector: 'app-produkt-detail-feststellungen',
  templateUrl: './produkt-detail-feststellungen.component.html',
  styleUrls: ['./produkt-detail-feststellungen.component.scss'],
  providers: [ProduktDetailFeatureNotizenService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailFeststellungenComponent extends ProduktDetailSchadenComponentBase<Feststellungen, FeststellungenInput, FeststellungenPosition, SchadenGruppenDisplay> implements OnInit {
  protected featureName = PRODUKT_CONFIG_FEATURES.Feststellungen.name;
  protected positionenName = PRODUKT_CONFIG_FEATURES.Feststellungen.fields.Positionen.name;
  protected positionenFieldConfig = PRODUKT_CONFIG_FEATURES.Feststellungen.fields.Positionen.fields;
  protected costGroupName = this.positionenFieldConfig.Preis.name;

  public fieldBeschreibung = PRODUKT_CONFIG_FEATURES.Feststellungen.fields.Umfaenge.fields.Beschreibung.name;
  public schadenDisplay = SchadenGruppenDisplay;
    protected display$ = new BehaviorSubject(SchadenGruppenDisplay.None);

  public positionen: ViewFormArray;
  public loading$ = new BehaviorSubject<boolean>(false);
  public loadingGetKalkulationData$ = new BehaviorSubject<boolean>(false);
  public hasFahrzeugExternalServiceReference = false;

  public obergruppe = new ObjectValues(SchadenObergruppe);

  public umfaenge: ViewFormArray;
  public umhaengeName = PRODUKT_CONFIG_FEATURES.Feststellungen.fields.Umfaenge.name;

  public kalkulation: UntypedFormGroup;
  public kalkulationFields;
  public sphinx;

  @ViewChild('untergruppe', {static: true})
  public untergruppeTemplate: TemplateRef<any>;

  @ViewChild('dialogKalkulationConfirm', { static: true })
  public dialogKalkulationConfirmTemplate: TemplateRef<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    produktConfigResolveService: ProduktConfigResolveService,
    produktDetailResolveService: ProduktDetailResolveService,
    produktFeststellungenService: ProduktFeststellungenService,
    formViewFactory: ProduktDetailFeststellungenFormViewFactory,
    currencyFormatter: CurrencyFormatterService,
    modelFileService: ModelFileService,
    viewportService: ViewportService,
    notizenService: ProduktDetailFeatureNotizenService,
    updateWerteService: UpdateWerteService,
    clipboard: Clipboard,
    public snackBarService: SnackBarService,
    public positionFormViewFactory: ProduktDetailFeststellungenPositionFormViewFactory,
    public templateDialogService: TemplateDialogService,
    private readonly auth: AuthService,
    private readonly einstellungenService: EinstellungenService,
    private readonly textbausteineService: TextbausteineService,
    private readonly datCalculateProService: DatCalculateProService,
    private readonly datKalkulationService: DatKalkulationService,
    private readonly kalkulationDownloadService: KalkulationDownloadService,
    private readonly produktDetailKalkulationFormViewFactory: ProduktDetailKalkulationFormViewFactory,
  ) {
    super(produktConfigResolveService,
    produktDetailResolveService,
    produktFeststellungenService,
    positionFormViewFactory,
    templateDialogService,
    snackBarService,
    formViewFactory,
    currencyFormatter,
    modelFileService,
    viewportService,
    notizenService,
    updateWerteService,
    clipboard);
    Assert.notNullOrUndefined(clipboard, 'clipboard');
    Assert.notNullOrUndefined(auth, 'auth');
    Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
    Assert.notNullOrUndefined(positionFormViewFactory, 'positionFormViewFactory');
    Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
    Assert.notNullOrUndefined(textbausteineService, 'textbausteineService');
    Assert.notNullOrUndefined(modelFileService, 'modelFileService');
    Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
    Assert.notNullOrUndefined(viewportService, 'viewportService');
    Assert.notNullOrUndefined(datCalculateProService, 'datCalculateProService');
    Assert.notNullOrUndefined(datKalkulationService, 'datKalkulationService');
    Assert.notNullOrUndefined(kalkulationDownloadService, 'kalkulationDownloadService');
    Assert.notNullOrUndefined(produktDetailKalkulationFormViewFactory, 'produktDetailKalkulationFormViewFactory');
    Assert.notNullOrUndefined(notizenService, 'notizenService');
    Assert.notNullOrUndefined(einstellungenService, 'einstellungenService');
    Assert.notNullOrUndefined(updateWerteService, 'updateWerteService');

    this.prefillUmfaenge();
  }

  public ngOnInit(): void {
    this.intensitaet = new EnumValues(SchadenIntensitaet);
    super.ngOnInit();
    const nameKalkulation = PRODUKT_CONFIG_FEATURES.Kalkulation.name;
    this.setModelAndDisplay();
    this.initKalkulation(nameKalkulation);
    this.subscriptions.push(this.datKalkulationService.getKalkulationStatusSubscription(this.produkt, this.kalkulation, this.loadingGetKalkulationData$));
    this.umfaenge = <ViewFormArray>this.form.get('umfaenge');
    this.hasFahrzeugExternalServiceReference = !!this.produkt.fahrzeug?.fahrzeugExternalServiceReference?.identifier;
    this.sphinx = SphinxClass.getInstance();
  }

  public onDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.datKalkulationService.removeKalkulationStorageListener(this.produkt.id);
    super.ngOnDestroy();
  }

  showKalkulation(): Observable<boolean> {
    return this.viewport$.pipe(take(1),
        mergeMap(viewport => {
          if (viewport !== Viewport.Mobile) {
            return this.datKalkulationService.getIsKalkulationActive();
          }
          return of(false);
        }));
  }

  public onOpenKalkulation(): void {
    if (this.produkt?.kalkulation?.dossierId) {
      this.display$.next(SchadenGruppenDisplay.Daten);
      const datPageConfig = {
        firstPage: DatFirstPage.MODEL,
        currentPage: DatFirstPage.EQUIPMENT_PAGE
      };
      this.datKalkulationService.initIframe(document, this.sphinx, datPageConfig, this.loading$, this.produkt, this.kalkulation, true);
    } else {
      this.openConfirmKalkulationDialog();
    }
  }

  public onModelLoad(result: ModelLoadResult): void {
    Assert.notNullOrUndefined(result, 'modelLoadStatus');
    if (result === ModelLoadResult.None) {
      this.modelDisabled$.next(true);
      this.onDisplayChange(SchadenGruppenDisplay.Manuell);
      this.snackBarService.warning('modell.couldNotLoad');
    } else if (result === ModelLoadResult.Fallback) {
      this.snackBarService.info('modell.fallback');
    }
  }

  public onAddUmfangClicked(value: string): void {
    const newUmfang = new ViewFormGroup({
      obergruppe: new ViewFormControl(value),
      beschreibung: new ViewFormArray([])
    });
    this.umfaenge.insert(0, newUmfang);
    this.umfaenge.updateValueAndValidity();
  }

  public onDeleteUmfangClicked(index: number): void {
    this.umfaenge.removeAt(index);
    this.umfaenge.updateValueAndValidity();
  }

  public downloadKalkulation(): void {
    this.auth.getClaims().pipe(first()).subscribe( claims => {
      if (claims && claims['custom:buero_id']) {
        this.kalkulationDownloadService.download(claims['custom:buero_id'], this.produkt.id).pipe(first()).subscribe(url => {
            if(url) {
              window.open(url);
            } else {
              this.snackBarService.error('schaden.kalkulation.download.error');
            }
          }
        );
      }
    });
  }

  public displayModel(): boolean {
    if (this.display$?.value === SchadenGruppenDisplay.Exterieur ||
        this.display$?.value === SchadenGruppenDisplay.Interieur ||
        this.display$?.value === SchadenGruppenDisplay.Technik) {
          return true;
    }
    return false;
  }

  protected createForm(): ViewFormGroup {
    const form = super.createForm();
    this.umfaenge = <ViewFormArray>form.get(this.umhaengeName);
    return form;
  }

  protected selectUntergruppe(obergruppe: SchadenObergruppe): Observable<FeststellungenPosition> {
    const fields = this.positionenFields;
    const form = this.positionFormViewFactory.create({obergruppe}, fields);
    const buttons = [this.templateDialogService.getCancelButtonSetting()];

    let untergruppen = OBERGRUPPE_TO_UNTERGRUPPE_MAP.get(obergruppe);
    if (!untergruppen) {
      untergruppen = [<any>obergruppe];
    }
    const values = new ArrayValues(<string[]>untergruppen, SchadenUntergruppe);

    const data: ProduktDetailFeststellungenUntergruppeDialogData = {form, values};
    const dialog = this.templateDialogService.open(obergruppe, buttons, this.untergruppeTemplate, data, true);

    const promise = new Promise<FeststellungenPosition>((resolve, reject) => {
      let valueChangeSubscription = form.valueChanges.subscribe({
        next: (value) => {
          valueChangeSubscription.unsubscribe();
          valueChangeSubscription = null;
          dialog.close();
          resolve(value);
        },
        error: (error) => reject(new Error(error))
      });
      dialog.afterClosed().pipe(take(1)).subscribe({
        next: () => {
          if (valueChangeSubscription) {
            valueChangeSubscription.unsubscribe();
          } else {
            resolve(null);
          }
        },
        error: (error) => reject(new Error(error))
      });
    });
    return from(promise);
  }

  private prefillUmfaenge(): void {
    if (this.produkt && !this.produkt.feststellungen?.umfaenge) {
      this.produkt.feststellungen.umfaenge = [];
      const obergruppenValues = new ObjectValues(UmfangObergruppe);

      for (let index = 0; index < obergruppenValues.keys.length; index++) {
        const key = obergruppenValues.keys[index];
        this.produkt.feststellungen.umfaenge.push({
          id: `${this.produkt.id}:${index}`,
          obergruppe: obergruppenValues.values[ key ],
          obergruppeName: key,
          beschreibung: [],
          __typename: 'Umfang'
        });
      }

      this.prefillStandardTextbausteine();
    }
  }

  private prefillStandardTextbausteine() {
    if (this.produkt?.feststellungen?.umfaenge?.length === 0) {
      return;
    }

    this.produkt.feststellungen.umfaenge.forEach((umfang, index) =>
      this.textbausteineService.getTextbausteine(umfang.obergruppeName, this.fieldBeschreibung).pipe(take(1)).subscribe({
        next: result => {
          if (result) {
            const resultFiltered = <Textbaustein[]> result.filter(value => value.standard === true);
            if (resultFiltered.length > 0 && this.umfaenge) {
                resultFiltered.forEach(baustein => (<ViewFormArray>(this.umfaenge.at(index) as ViewFormGroup).get('beschreibung')).push(
                    this.textbausteineService.createTextbausteinFormGroup(baustein)));
            }
          }
        }
      })
    );
  }

  private initKalkulation(name: string) {
    this.kalkulationFields = this.getFeatureByName(name).fields;
    this.kalkulation = this.produktDetailKalkulationFormViewFactory.create(this.produkt.kalkulation, this.kalkulationFields);
  }

  private openConfirmKalkulationDialog(): void {
    const title = `${this.name}.kalkulation.dialog.title`;
    const buttons = [this.templateDialogService.getCancelButtonSetting(), this.templateDialogService.getConfirmButtonSetting()];

    this.templateDialogService.openTemplate(title, buttons,
      this.dialogKalkulationConfirmTemplate).pipe(take(1)).subscribe(result => {
      if (result?.name === this.templateDialogService.getConfirmButtonSetting().title) {
        this.display$.next(SchadenGruppenDisplay.Daten);
          const datPageConfig = {
              firstPage: DatFirstPage.MODEL,
              currentPage: DatFirstPage.EQUIPMENT_PAGE
          };
        this.datKalkulationService.initIframe(document, this.sphinx, datPageConfig, this.loading$, this.produkt, this.kalkulation, true);
      }
    });
  }

  protected setModelAndDisplay() {
    this.einstellungenService.getBenutzer().pipe(take(1)).subscribe(benutzerEinstellungen => {
        if (benutzerEinstellungen?.deactivate3dModel) {
          this.modelDisabled$.next(benutzerEinstellungen.deactivate3dModel);
          this.display$.next(SchadenGruppenDisplay.Manuell);
        } else {
          this.modelDisabled$.next(false);
          this.display$.next(SchadenGruppenDisplay.Exterieur);
        }
      }
    );
  }
}
